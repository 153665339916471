import { types, flow, getSnapshot } from 'mobx-state-tree';
import firebase from './../helpers/firebase';

const db = firebase.firestore();

export default types
  .model('Config', {
    village_report_emails: types.array(types.string),
    contact_us_recipient: '',
  })
  .views((self) => ({}))
  .volatile((self) => ({
    loading: true,
    saving: false,
    saved: false,
  }))
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
    save: flow(function* () {
      self.saving = true;
      const data = getSnapshot(self);
      yield db.collection('system').doc('config').update(data);
      self.saving = false;
      self.saved = true;
    }),
    load: flow(function* () {
      self.loading = true;
      self.saved = false;
      const result = yield db.collection('system').doc('config').get();
      const config = result.data() || {};
      for (let key of Object.keys(config)) {
        self[key] = config[key];
      }
      self.loading = false;
    }),
  }));
