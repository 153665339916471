import { types, flow, getSnapshot, applySnapshot } from 'mobx-state-tree';
import User from './User';
import firebase from 'firebase/app';

export default types
  .model('Auth', {
    user: types.maybeNull(User),
  })
  .volatile((self) => ({
    loading: false,
  }))
  .views((self) => ({
    get isLoggedIn() {
      return !!self.user;
    },
  }))
  .actions((self) => ({
    _setUser(newUserData) {
      if (self.user) {
        self.user.updateData(newUserData);
      } else {
        self.user = newUserData;
      }
      localStorage.setItem('ap_auth_data', JSON.stringify(getSnapshot(self)));
    },
    init() {
      const data = localStorage.getItem('ap_auth_data');
      if (data) {
        applySnapshot(self, JSON.parse(data));
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            // User is signed in.
            // listen for data changes
            firebase
              .firestore()
              .collection('users')
              .doc(self.user.id)
              .onSnapshot((snap) => {
                const data = Object.assign({}, snap.data(), { id: snap.id });
                self._setUser(data);
              });
          } else {
            self.logout();
          }
        });
      }
    },
    login: flow(function* (username, password) {
      self.loading = true;

      let authResult, user;

      try {
        authResult = yield firebase.auth().signInWithEmailAndPassword(username, password);
        user = yield firebase.firestore().collection('users').doc(authResult.user.uid).get();
      } catch (err) {
        self.loading = false;
        alert('Failed to login, please check your credentials');
        return;
      }

      if (!user.get('roles.admin') && !user.get('roles.village_moderator') && !user.get('roles.marketing_admin')) {
        self.loading = false;
        alert('You are not authorized to access this panel');
        return;
      }
      self.user = Object.assign({}, user.data(), {
        id: user.id,
      });

      localStorage.setItem('ap_auth_data', JSON.stringify(getSnapshot(self)));
    }),
    logout() {
      localStorage.removeItem('ap_auth_data');
      self.user = null;
      window.location.pathname = '/';
    },
  }));
