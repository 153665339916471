import { types } from 'mobx-state-tree';

const ModalManager = types
  .model('ModalManager', {
    active: '',
  })
  .views((self) => ({
    isOpen(id) {
      return self.active === id;
    },
  }))
  .actions((self) => ({
    open(id) {
      self.active = id;
    },
    close() {
      self.active = '';
    },
  }));

export default ModalManager;
