import { types, flow, getParent, getSnapshot } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
const db = firebase.firestore();

const ScheduleMilestone = types
  .model('ScheduleMilestone', {
    id: types.string,
    description: types.string,
  })
  .views((self) => ({
    get weekIndex() {
      return self.id.replace('w', '');
    },
    get drafts() {
      return getParent(self, 2).drafts;
    },
  }))
  .actions((self) => ({
    getDraft() {
      const drafts = getParent(self, 2).drafts;
      const existing = drafts.getUnpublishedByItemID(self.id);
      if (existing) {
        return existing;
      } else {
        // create the draft
        return drafts.createForItem(
          'schedule-milestone',
          `Milestone for week (age): ${self.weekIndex}`,
          getSnapshot(self),
          'edit'
        );
      }
    },
    set(key, value) {
      self[key] = value;
      self.getDraft().setItemData(key, value);
    },
    setDescription(desc) {
      desc = JSON.stringify(desc);
      self.description = desc;
      self.getDraft().setItemData('description', desc);
    },
    delete() {
      self.getDraft().deleteItem();
      getParent(self, 2).remove(self.id);
    },
    save() {
      return self.getDraft().save();
    },
  }));

export default types
  .model('ScheduleMilestoneManager', {
    items: types.array(ScheduleMilestone),
  })
  .volatile((self) => ({
    loading: false,
    loaded: false,
  }))
  .views((self) => ({
    get drafts() {
      return getParent(self).drafts;
    },
    get isEmpty() {
      return self.items.length === 0;
    },
    getByWeek(weekIndex) {
      const id = `w${weekIndex}`;
      return self.items.find((item) => item.id === id);
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      if (self.loading) return;
      yield self.drafts.ensureListeningUnpublished();
      self.loading = true;
      const result = yield db.collection('schedule_milestones').get();
      self.items = self.drafts.replaceData('schedule-milestone', snapToArray(result));
      self.loading = false;
      self.loaded = true;
    }),
    add: function (weekIndex, description) {
      let milestone = {
        id: `w${weekIndex}`,
        description,
      };
      self.items.push(milestone);
      milestone = self.items[self.items.length - 1];
      milestone.getDraft().setOperation('create');
      return milestone;
    },
    remove(id) {
      self.items.splice(
        self.items.findIndex((item) => item.id === id),
        1
      );
    },
  }));
