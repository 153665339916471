import { types, flow, getParent } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
import { when } from 'mobx';
import Timestamp from './Timestamp';
import moment from 'moment-timezone';

const db = firebase.firestore();

function onlineTimeRanges(logs, timezone) {
  const ranges = [];
  let allOfLogs = logs.filter((log) => log.type === 'online' || log.type === 'offline');
  let oflogs = [];
  let lastof = '';
  // clean up the online/offline events to remove any incorrect duplicates
  for (let log of allOfLogs) {
    if (!lastof) {
      lastof = log.type;
      oflogs.push(log);
    } else if (lastof === 'online' && log.type === 'offline') {
      lastof = log.type;
      oflogs.push(log);
    } else if (lastof === 'offline' && log.type === 'online') {
      lastof = log.type;
      oflogs.push(log);
    }
  }

  oflogs.forEach((log) => {
    // online time
    if (log.type === 'online') {
      let nextOfflineLog = oflogs.find((l) => l.time.milliseconds > log.time.milliseconds && l.type === 'offline');
      if (nextOfflineLog) {
        ranges.push({
          from: log.time.date,
          to: nextOfflineLog.time.date,
          fromLog: log,
          toLog: nextOfflineLog,
        });
      } else if (oflogs.indexOf(log) === oflogs.length - 1) {
        // if there is no log for "offline" after this, it means the user worked through midnight. Count hours till midnight
        // count time till midnight (local time)
        let onlineStop = moment(log.time.milliseconds).tz(timezone);
        onlineStop.hours(23).minutes(59).seconds(59);
        ranges.push({
          from: log.time.date,
          to: new Date(onlineStop.valueOf()),
          fromLog: log,
          toLog: null,
        });
      }
    }
    if (log.type === 'offline') {
      // if there is no log for "online" before this, it means the user worked through midnight. Count hours from midnight
      if (oflogs.indexOf(log) === 0) {
        // count time from midnight (local time)
        let onlineStart = moment(log.time._seconds * 1000).tz(timezone);
        onlineStart.hours(0).minutes(0).seconds(0);
        ranges.push({
          from: new Date(onlineStart.valueOf()),
          to: log.time.date,
          fromLog: null,
          toLog: log,
        });
      }
    }
  });

  return ranges;
}

function hasRepliedFromCUI(timezone, logs, postID, commentCreatedDate) {
  /**
   * Has the reply been made within an online session
   * Has the post under which reply was made been opened in that online session
   */

  // make online time ranges
  const ranges = onlineTimeRanges(logs, timezone);
  let time = commentCreatedDate.getTime();
  let time1MinEarlier = new Date(commentCreatedDate.getTime());
  time1MinEarlier.setMinutes(time1MinEarlier.getMinutes() - 1);
  for (let range of ranges) {
    let rFrom = range.from.getTime();
    let rTo = range.to.getTime();
    if ((time >= rFrom && time <= rTo) || (time1MinEarlier >= rFrom && time1MinEarlier <= rTo)) {
      // it happened during this online range
      // has the post been opened during this range?
      let fromIndex = range.fromLog ? logs.indexOf(range.fromLog) : 0;
      let toIndex = range.toLog ? logs.indexOf(range.toLog) : logs.length - 1;
      let onlineSessionLogs = logs.slice(fromIndex, toIndex);
      if (onlineSessionLogs.find((l) => l.type === 'post-opened' && l.meta && l.meta.postId === postID)) {
        return true;
      }
    }
  }
  return false;
}

export const ACTIVITY_TYPES = [
  'replied',
  'status-updated',
  'assigned-updated',
  'post-opened',
  'post-closed',
  'online',
  'offline',
  'reply-started',
  'deleted-post', // { postId, postSubject, postDescription, postAuthorUID }
  'deleted-comment', // { postId, commentId, commentText, commentAuthorUID }
  'updated-frozen', // { postId, frozen }
  'updated-success', // { postId, success }
];

export const LogEntry = types
  .model('ModActivityLogEntry', {
    id: '',
    type: types.string,
    meta: types.frozen(),
    time: Timestamp,
    uid: types.string,
  })
  .views((self) => ({
    get user() {
      return getParent(self, 2).user;
    },
    get manager() {
      return getParent(self, 2);
    },
    get message() {
      switch (self.type) {
        case 'online':
          return 'Online';
        case 'post-opened':
          return 'Opened post';
        case 'reply-started':
          return 'Started writing a reply';
        case 'replied':
          if (self.user && self.user.timezone) {
            let origin = hasRepliedFromCUI(
              self.user && self.user.timezone,
              self.manager.sorted,
              self.meta.postId,
              self.time.date
            )
              ? 'cui'
              : 'app';
            return origin === 'app' ? `Replied to post (App)` : 'Replied to post (Dashboard)';
          } else {
            return 'Replied';
          }
        case 'status-updated':
          return "Updated post status from '" + self.meta.oldModStatus + "' to '" + self.meta.newModStatus + "'";
        case 'assigned-updated':
          return 'Updated assigned consultants';
        case 'post-closed':
          return 'Closed post';
        case 'deleted-post':
          return `Deleted post: ${self.meta.postSubject}, by UID: ${self.meta.postAuthorUID}`;
        case 'deleted-comment':
          return `Deleted a post comment by UID: ${self.meta.commentAuthorUID}`;
        case 'updated-frozen':
          return `Updated frozen status of a post to: ${self.meta.frozen}`;
        case 'updated-success':
          return `Updated success story status of a post to: ${self.meta.success}`;
        default:
          return self.type;
      }
    },
    get localTime() {
      return moment.tz(self.time.milliseconds, self.user && self.user.timezone).format('h:mm:ss a');
    },
  }));

export default types
  .model('ModActivityLog', {
    entries: types.array(LogEntry),
  })
  .volatile((self) => ({
    loaded: false,
    loading: false,
  }))
  .views((self) => ({
    get sorted() {
      return self.entries;
    },
    get user() {
      return getParent(self);
    },
  }))
  .actions((self) => ({
    load: flow(function* (day) {
      if (self.loading) {
        return yield when(() => self.loaded);
      }
      self.loading = true;
      const start = moment.tz(day, self.user.timezone);
      const end = moment.tz(start, self.user.timezone).date(start.date() + 1);
      // console.log(start.toDate(), end.toDate());
      const query = db
        .collection('mod_activity_log')
        .where('uid', '==', self.user.id)
        .where('time', '>', start.toDate())
        .where('time', '<', end.toDate());
      const result = yield query.get();
      const entries = snapToArray(result.docs);
      entries.sort((a, b) => {
        return a.time.toMillis() - b.time.toMillis();
      });

      self.entries = entries;

      self.loaded = true;
      self.loading = false;
    }),
  }));
