import { types, flow } from 'mobx-state-tree';
import firebase from '../helpers/firebase';
import { snapToArray } from '../helpers/firestore';
import MetricCollection from './MetricCollection';
import MetricSeriesCollection from './MetricSeriesCollection';
import { when } from 'mobx';

const db = firebase.firestore();

export default types
  .model('SalesMetricsManager', {
    metrics: types.optional(MetricCollection, {}),
    series: types.optional(MetricSeriesCollection, {}),
  })
  .volatile((self) => ({
    loaded: false,
    loading: false,
  }))
  .views((self) => ({
    getPlatformNewSubsPerDay(platform) {
      let name;
      if (platform === 'appStore') {
        name = 'newAppStoreSeries';
      } else if (platform === 'shopify') {
        name = 'newShopifySeries';
      } else if (platform === 'stripe') {
        name = 'newStripeSeries';
      } else {
        name = 'newPlayStoreSeries';
      }
      return self.series.get(name);
    },
    get totalInactiveSubs() {
      let appStore = self.metrics.getNowMetric('inactive_appStore') || { value: 0 };
      let playStore = self.metrics.getNowMetric('inactive_playStore') || { value: 0 };
      let stripe = self.metrics.getNowMetric('inactive_stripe') || { value: 0 };
      return appStore.value + playStore.value + stripe.value;
    },
    get totalActiveSubs() {
      let appStore = self.metrics.getNowMetric('active_appStore') || { value: 0 };
      let playStore = self.metrics.getNowMetric('active_playStore') || { value: 0 };
      let stripe = self.metrics.getNowMetric('active_stripe') || { value: 0 };
      return appStore.value + playStore.value + stripe.value;
    },
    get newOrdersPerDay() {
      return self.series.getCombinedMetric('day', 'newShopifySeries', 'avg').toFixed(1);
    },
    get endedOrdersPerDay() {
      return self.series.getCombinedMetric('day', 'endedShopifySeries', 'avg').toFixed(1);
    },
    get newSubsPerDay() {
      let appStore = self.series.getCombinedMetric('day', 'newAppStoreSeries', 'avg', 30);
      let playStore = self.series.getCombinedMetric('day', 'newPlayStoreSeries', 'avg', 30);
      let stripe = self.series.getCombinedMetric('day', 'newStripeSeries', 'avg', 30);
      return (appStore + playStore + stripe).toFixed(1);
    },
    newSubs(mode, dateRange) {
      const platforms = ['newAppStoreSeries', 'newPlayStoreSeries', 'newStripeSeries'];
      return platforms.reduce(
        (total, platform) => total + self.series.__getCombinedMetric(platform, { mode, dateRange }),
        0
      );
    },
    get cancellationsPerDay() {
      let appStore = self.series.getCombinedMetric('day', 'endedAppStoreSeries', 'avg', 30);
      let playStore = self.series.getCombinedMetric('day', 'endedPlayStoreSeries', 'avg', 30);
      let stripe = self.series.getCombinedMetric('day', 'endedStripeSeries', 'avg', 30);
      return (appStore + playStore + stripe).toFixed(1);
    },
    cancellations(mode, dateRange) {
      const platforms = ['endedAppStoreSeries', 'endedPlayStoreSeries', 'endedStripeSeries'];
      return platforms.reduce(
        (total, platform) => total + self.series.__getCombinedMetric(platform, { mode, dateRange }),
        0
      );
    },
    get totalShopifyAndSub() {
      return self.metrics.getNowMetric('totalWithLegacyAndSubscription').value;
    },
    get totalShopifyOnly() {
      return self.metrics.getNowMetric('totalWithLegacyProgramOnly').value;
    },
    get OTPOrderPerDay() {
      const products = ['newborn-sleep', 'baby-sleep', 'toddler-sleep', 'village-access'];
      let productsOrderPerDay = products.reduce((current, product) => {
        return current + self.series.getCombinedMetric('day', `newProduct${product}Series`, 'avg');
      }, 0);
      return productsOrderPerDay.toFixed(1);
    },
    totalOTPOrder({ start, end }) {
      const products = ['newborn-sleep', 'baby-sleep', 'toddler-sleep', 'village-access'];

      const totalOTPOrder = products.reduce((total, productID) => {
        const purchases = self.series.__getCombinedMetric(`newProduct${productID}Series`, {
          dateRange: { start, end },
        });
        return total + purchases;
      }, 0);

      return totalOTPOrder;
    },
    totalRevenue({ start, end }) {
      const products = [
        'ultimate-subscription-monthly',
        'ultimate-subscription-quarterly',
        'ultimate-subscription-annually',
        'newborn-sleep',
        'baby-sleep',
        'toddler-sleep',
        'village-access',
      ];

      const totalRevenue = products.reduce((totalRevenue, currentProduct) => {
        const productRevenue = self.series.__getCombinedMetric(`revenueProduct${currentProduct}Series`, {
          dateRange: {
            start,
            end,
          },
        });
        return totalRevenue + productRevenue;
      }, 0);

      return totalRevenue;
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      if (self.loading) {
        yield when(() => self.loaded);
        return;
      }
      if (self.loaded) {
        return;
      }
      const [metricsResult, processedSeries, usersMetricsResult] = yield Promise.all([
        db.collection('metrics').where('objectType', '==', 'purchase').where('timePeriod', '==', 'now').get(),
        db.collection('metrics').where('objectType', '==', 'purchase').where('timePeriod', '==', 'processed').get(),
        db
          .collection('metrics')
          .where('objectType', '==', 'user')
          .where('objectID', '==', '')
          .where('timePeriod', '==', 'now')
          .get(),
      ]);
      const metrics = snapToArray(metricsResult);
      self.metrics.merge(metrics);
      const usersMetrics = snapToArray(usersMetricsResult);
      self.metrics.merge(usersMetrics);

      const series = snapToArray(processedSeries);
      self.series.setData(series);

      self.loading = false;
      self.loaded = true;
    }),
  }));
