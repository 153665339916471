// @flow
import React, { Component, Suspense } from 'react';
import { observer, inject } from 'mobx-react';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = React.lazy(() => import('../layouts/Auth'));
const HorizontalLayout = React.lazy(() => import('../layouts/Horizontal'));

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = (WrappedComponent) => {
  const HOC = class extends Component {
    /**
     * Returns the layout component based on different properties
     */
    getLayout = () => {
      if (!this.props.rootStore.auth.isLoggedIn) return AuthLayout;
      return HorizontalLayout;
    };

    componentWillUnmount() {
      this.props.rootStore.ui.pageWillChange();
    }

    render() {
      const Layout = this.getLayout();
      return (
        <Suspense fallback={loading()}>
          <Layout {...this.props}>
            <WrappedComponent {...this.props} />
          </Layout>
        </Suspense>
      );
    }
  };

  return inject('rootStore')(observer(HOC));
};

export default withLayout;
