import { types, flow } from 'mobx-state-tree';
import Timestamp from './Timestamp';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
const db = firebase.firestore();

const ItemChange = types.model('ItemChange', {
  operation: types.string,
  itemLabel: types.string,
  itemId: types.string,
  itemType: types.string,
});

const ContentCommit = types
  .model('ContentCommit', {
    id: types.string,
    message: types.string,
    authorUID: types.string,
    changedItems: types.array(ItemChange),
    time: types.maybe(Timestamp),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default types
  .model('ContentCommitManager', {
    items: types.array(ContentCommit),
  })
  .volatile((self) => ({
    loading: false,
    loaded: false,
  }))
  .views((self) => ({
    get sorted() {
      const items = self.items.slice(0);
      items.sort((a, b) => {
        return (b.time.milliseconds || Math.max()) - (a.time.milliseconds || Math.max());
      });
      return items;
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      if (self.loading) return;
      self.loading = true;
      const result = yield db.collection('content_commits').get();
      const items = snapToArray(result);
      // descending order by time
      self.items = items;
      self.loading = false;
      self.loaded = true;
    }),
  }));
