import { types, flow } from 'mobx-state-tree';
import Village from './Village';
import { snapToArray } from './../helpers/firestore';
import firebase from './../helpers/firebase';
import VillageMetricsManager from './VillageMetricsManager';
const db = firebase.firestore();

export default types
  .model('VillageManager', {
    items: types.array(Village),
    metrics: types.optional(VillageMetricsManager, {}),
  })
  .volatile((self) => ({
    loaded: false,
    loading: false,
  }))
  .views((self) => ({
    get all() {
      return self.items;
    },
    get(id) {
      return self.items.find((et) => et.id === id);
    },
    getCombinedMetric(name, mode, formatted) {
      if (mode === 'sum') {
        return self.items.reduce((m, village) => {
          return m + village.getMetric(name);
        }, 0);
      } else if (mode === 'avg') {
        let sum = self.items.reduce((m, village) => {
          return m + village.getMetric(name);
        }, 0);
        let total = sum / self.items.length;
        if (formatted && name === 'avgTimeToFirstReply') {
          let minutes = Math.round(total / 60);
          let hours = Math.round(total / 3600);
          let days = Math.round(total / (3600 * 24));
          if (days > 1) {
            return days + 'days';
          } else if (hours > 1) {
            return hours + 'hrs';
          } else {
            return minutes + 'mins';
          }
        } else {
          return total;
        }
      }
    },
    getCombinedStatusCount(name) {
      return self.items.reduce((m, village) => {
        return m + village.getStatusCount(name);
      }, 0);
    },
  }))
  .actions((self) => ({
    load: flow(function* (mode) {
      if (self.loaded || self.loading) return;
      self.loading = true;
      const villageData = yield db.collection('villages').orderBy('sortIndex', 'asc').get();
      self.items = snapToArray(villageData);
      // also, load counts and listen to changes there
      yield self.metrics.load(mode);
      self.loaded = true;
      self.loading = false;
    }),
    unload() {
      self.metrics.unload();
    },
  }));
