import { types, getParent } from 'mobx-state-tree';
import Icon from './Icon';

export default types
  .model('Village', {
    id: types.identifier,
    name: types.string,
    description: types.string,
    purchaseURL: types.string,
    topics: types.array(types.string),
    sortIndex: types.number,
    public: types.boolean,
    onlyModeratorsPost: types.boolean,
    icon: Icon,
  })
  .views((self) => ({
    get manager() {
      return getParent(self, 2);
    },
    getGraphSeries(name) {
      const man = self.manager;
      return man.metrics.series.get(name + 'Series', self.id);
    },
    getMetric(name, formatted) {
      const man = self.manager;
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 15);
      startDate = startDate.toISOString().split('T').shift();
      if (name === 'avgDayPostsCount') {
        let series = man.metrics.series.get('postCountSeries', self.id);
        let total = series.reduce((m, metric) => m + metric.y, 0);
        return series.length > 0 ? Math.round(total / series.length) : 0;
      } else if (name === 'avgDayCommentCount') {
        let series = man.metrics.series.get('commentCountSeries', self.id);
        let total = series.reduce((m, metric) => m + metric.y, 0);
        return series.length > 0 ? Math.round(total / series.length) : 0;
      } else if (name === 'avgTimeToFirstReply') {
        let series = man.metrics.series.get('timeToFirstReplySeries', self.id);
        let total = series.reduce((m, metric) => m + metric.y, 0);
        let seconds = series.length > 0 ? Math.round(total / series.length) : 0;
        if (formatted) {
          let minutes = Math.round(seconds / 60);
          let hours = Math.round(seconds / 3600);
          let days = Math.round(seconds / (3600 * 24));
          if (days > 1) {
            return days + 'days';
          } else if (hours > 1) {
            return hours + 'hrs';
          } else {
            return minutes + 'mins';
          }
        } else {
          return seconds;
        }
      }
    },
    getStatusCount(name) {
      name = name.replace('all', 'total');
      const metricName = `${name}PostCount`;
      const counter = self.manager.metrics.statusCounters.find(
        (item) => item.metric === metricName && item.objectID === self.id
      );
      return (counter && counter.value) || 0;
    },
  }));
