import { types, flow } from 'mobx-state-tree';
import EventType from './EventType';
import { snapToArray } from './../helpers/firestore';
import firebase from './../helpers/firebase';
const db = firebase.firestore();

export default types
  .model('EventTypeManager', {
    eventTypes: types.array(EventType),
  })
  .views((self) => ({
    get empty() {
      return self.eventTypes.length === 0;
    },
    get loading() {
      return self.empty;
    },
    get(id) {
      return self.eventTypes.find((et) => et.id === id);
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      const eventTypeData = yield db.collection('event_types').orderBy('name', 'asc').get();
      self.eventTypes = snapToArray(eventTypeData);
    }),
  }));
