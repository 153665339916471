export default {
  firebase: {
    apiKey: "AIzaSyCoX0z5-WAUwYwYhhdUBxR9MDueoVcC9wc",
    authDomain: "littleones-prod.firebaseapp.com",
    databaseURL: "https://littleones-prod.firebaseio.com",
    projectId: "littleones-prod",
    storageBucket: "littleones-prod.appspot.com",
    messagingSenderId: "991873835613",
    appId: "1:991873835613:web:5a5595ab5eac2d51be23b4"
  }
}
