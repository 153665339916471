const { createNullCache } = require('@algolia/cache-common');
const algoliasearch = require('algoliasearch');
const client = algoliasearch('WFKRM6EQOO', 'ebc85414abeefce8a0f93bd224310539', {
  responsesCache: createNullCache(),
  requestsCache: createNullCache(),
});
const indexName =
  typeof window !== 'undefined' && window.location.href.includes('admin.littleones.co') ? 'prod_posts' : 'devel_posts';
const index_asc = client.initIndex(indexName + '_asc');
const index_desc = client.initIndex(indexName);

export default {
  posts_asc: index_asc,
  posts_desc: index_desc,
};
