import { types, flow } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
import MetricCollection from './MetricCollection';
import MetricSeriesCollection from './MetricSeriesCollection';
import { when } from 'mobx';

const db = firebase.firestore();

export default types
  .model('SubscriptionMetricsManager', {
    metrics: types.optional(MetricCollection, {}),
    series: types.optional(MetricSeriesCollection, {}),
  })
  .volatile((self) => ({
    loaded: false,
    loading: false,
  }))
  .views((self) => ({
    get signUpsPerDay() {
      return self.series.getCombinedMetric('day', 'signUpCountSeries', 'avg').toFixed(1);
    },
    get total() {
      return self.metrics.getNowMetric('total').value;
    },
    get activeLast24Hours() {
      return self.metrics.getNowMetric('activeLast24Hours').value;
    },
    get activeLast7Days() {
      return self.metrics.getNowMetric('activeLast7Days').value;
    },
    get activeLast28Days() {
      return self.metrics.getNowMetric('activeLast28Days').value;
    },
    loCount(xVal) {
      const m = self.metrics.getByMetricTime('loCount', xVal);
      return (m && m.value) || '0';
    },
    loCountActive(xVal) {
      const m = self.metrics.getByMetricTime('loCountActive', xVal);
      return (m && m.value) || '0';
    },
    getDistributionSeries(metric) {
      return self.series.get(metric + 'Series');
    },
    get signUpsSeries() {
      return [
        {
          name: 'Sign Ups',
          data: self.series.get('signUpCountSeries'),
        },
      ];
    },
    get inviteClickedSeries() {
      return {
        name: 'Clicks on invitation link',
        data: self.series.get('inviteClickedSeries'),
      };
    },
    get inviteClaimedSeries() {
      return {
        name: 'Claimed invitations',
        data: self.series.get('inviteClaimedSeries'),
      };
    },
    get totalClickedInvites() {
      return self.series.getCombinedMetric('day', 'inviteClickedSeries', 'sum');
    },
    get totalClaimedInvites() {
      return self.series.getCombinedMetric('day', 'inviteClaimedSeries', 'sum');
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      if (self.loading) {
        yield when(() => self.loaded);
        return;
      }
      if (self.loaded) {
        return;
      }
      const [metricsResult, seriesResult] = yield Promise.all([
        db
          .collection('metrics')
          .where('objectType', '==', 'user')
          .where('objectID', '==', '')
          .where('timePeriod', '==', 'now')
          .get(),
        db.collection('metrics').where('objectType', '==', 'user').where('timePeriod', '==', 'processed').get(),
      ]);
      const metrics = snapToArray(metricsResult);
      self.metrics.merge(metrics);
      self.series.setData(snapToArray(seriesResult));
      self.loading = false;
      self.loaded = true;
    }),
  }));
