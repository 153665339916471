import { types, getSnapshot, getParent, flow } from 'mobx-state-tree';
import Icon from './Icon';
import Timestamp from './Timestamp';
import firebase from './../helpers/firebase';

const ProgramArticle = types
  .model('ProgramArticle', {
    id: types.identifier,
    programId: types.string, // program id to which this article belongs to
    meta: types.optional(
      types.model('ProgramArticleMeta', {
        randomIndex: -1, // <int> random number >= 0 and < 1,000,000 (1 million), used for fetching random documents
        ageWeeksMin: -1, // <int|undefined> from which age this article is relevant
        ageWeeksMax: -1, // <int|undefined> to which age in weeks this article is relevant
        serving: '', // <string> serving for recipes
      }),
      {}
    ),
    oldGID: '',
    sortIndex: 100000, // <int> Index to sort by in UI, in ascending order (0 is top position)
    title: '', // <string> title of the program article
    summary: '{"contents":[{ "p": "" }]}',
    content: '{"contents":[{ "p": "" }]}', // <string> content of the program article
    state: 'draft', // draft|published
    thumbnail: '',
    tags: types.array(types.string), // <array[string]> Tags of article. These can be for example “Trouble shooting”, “Getting started”, “Schedules”
    topic: '',
    access: types.optional(
      types.model({
        free: false,
      }),
      { free: false }
    ),
    type: types.enumeration(['text', 'video', 'download']),
    typeIcon: types.maybe(Icon), // <object> embedded article type icon
    lengthTime: 0, // <int> Length in minutes (to read/watch)
    lengthTimeText: '', // <string> Representing length as text
    created: types.maybe(Timestamp), // <timestamp> when post was created
    updated: types.maybe(Timestamp), // <timestamp> when post was last updated
  })
  .volatile((self) => ({
    saving: false,
    deleting: false,
  }))
  .views((self) => ({
    getDraft() {
      const drafts = getParent(self, 2).drafts;
      const existing = drafts.getUnpublishedByItemID(self.id);
      if (existing) {
        return existing;
      } else {
        // create the draft
        return drafts.createForItem('program-article', self.programId + ':' + self.title, getSnapshot(self), 'edit');
      }
    },
  }))
  .actions((self) => ({
    set(key, value) {
      if (key.includes('.')) {
        let obj;
        let keys = key.split('.');
        let last = keys.pop();
        obj = keys.reduce((m, key) => {
          return m[key];
        }, self);
        obj[last] = value;
      } else {
        self[key] = value;
      }
      self.getDraft().setItemLabel(self.programId + ':' + self.title);
      self.getDraft().setItemData(key, value);
    },
    update: flow(function* (key, value) {
      self.saving = true;
      self[key] = value;
      yield self.getDraft().updateItemData(key, value);
      self.saving = false;
    }),
    copy: flow(function* () {
      self.copying = true;
      let newArticleRef = firebase.firestore().collection('program_articles').doc();
      let id = newArticleRef.id;
      getParent(self, 2).pushArticle(
        Object.assign({}, self.getSnapshot(), { id, title: 'Copy of ' + self.title, state: 'draft' })
      );
      let newArticle = getParent(self)[getParent(self).length - 1];
      newArticle.getDraft().setOperation('create');
      yield newArticle.save();
      self.copying = false;
      return newArticle;
    }),
    save: flow(function* () {
      self.saving = true;
      yield self.getDraft().save();
      self.saving = false;
    }),
    delete: flow(function* () {
      self.deleting = true;
      yield self.getDraft().deleteItem();
      getParent(self, 2).removeArticle(self.id);
      self.deleting = false;
    }),
    getSnapshot() {
      return getSnapshot(self);
    },
  }));

export default ProgramArticle;
