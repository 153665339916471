import { types, getSnapshot } from 'mobx-state-tree';

export default types
  .model('Icon', {
    id: '',
    type: '',
    iconName: '',
    styles: types.model('IconStyles', {
      light: types.string,
      dark: types.string,
    }),
    backgroundColor: types.string,
  })
  .views((self) => ({
    get embedded() {
      return getSnapshot(self);
    },
  }));
