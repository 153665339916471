import { types } from 'mobx-state-tree';

const Alert = types.model('Alert', {
  color: types.optional(types.string, 'primary'),
  text: types.string,
  links: types.array(types.frozen()),
  useUncontrolled: types.optional(types.boolean, false),
});

export default Alert;
