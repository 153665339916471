import firebase, { FieldValue } from './firebase';

let hasBeenSetup = false;

export default function setupErrorHandler() {
  if (hasBeenSetup) {
    return;
  }
  if (typeof window !== 'undefined') {
    hasBeenSetup = true;
    window.onerror = function (message, file, lineNumber, columnNumber, error) {
      var trace = (error && error.trace) || '';
      var user = firebase.auth().currentUser;
      var uid = (user && user.uid) || '';
      var clientError = {
        uid: uid,
        message: message,
        url: window.location.href,
        file: file,
        stacktrace: trace,
        time: FieldValue.serverTimestamp(),
      };
      // save to firebase
      const timestamp = new Date().getTime();
      const id = timestamp + uid;
      firebase.firestore().collection('client_errors').doc(id).set(clientError);
    };
  }
}
