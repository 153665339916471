import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import * as FeatherIcon from 'react-feather';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const ActivityLog = React.lazy(() => import('../pages/activity-log'));
const Events = React.lazy(() => import('../pages/events'));
const Programs = React.lazy(() => import('../pages/programs'));
const ProgramView = React.lazy(() => import('../pages/programs/view'));
const DevEditorView = React.lazy(() => import('../pages/programs/dev-editor'));
const CreateArticleView = React.lazy(() => import('../pages/programs/new-article'));
const EditArticleView = React.lazy(() => import('../pages/programs/edit-article'));
const Settings = React.lazy(() => import('../pages/settings/index'));
const Content = React.lazy(() => import('../pages/content/index'));
const ContentEdit = React.lazy(() => import('../pages/content/edit-content'));
const ContentCreate = React.lazy(() => import('../pages/content/new-content'));
const ArticleSchedulePDF = React.lazy(() => import('../pages/programs/article-schedule-pdf'));
const SchedulePDF = React.lazy(() => import('../pages/programs/schedule-pdf'));
const Users = React.lazy(() => import('../pages/users'));
const UserView = React.lazy(() => import('../pages/users/view'));
const ShopifyCustomerView = React.lazy(() => import('../pages/shopify-customers/index'));
const CreateUser = React.lazy(() => import('../pages/users/create'));
const VillageHome = React.lazy(() => import('../pages/village/index'));
const VillageMyStats = React.lazy(() => import('../pages/village/my-stats'));
const VillageMyProfile = React.lazy(() => import('../pages/village/profile'));
const AnalyticsVillages = React.lazy(() => import('../pages/analytics/villages'));
const AnalyticsConsultants = React.lazy(() => import('../pages/analytics/consultants'));
const AnalyticsSales = React.lazy(() => import('../pages/analytics/sales'));
const AnalyticsUsers = React.lazy(() => import('../pages/analytics/users'));
const PublishContent = React.lazy(() => import('../pages/publish-content'));
const Notifications = React.lazy(() => import('../pages/notifications'));
const ProductsPage = React.lazy(() => import('../pages/products'));
const PurchaseLog = React.lazy(() => import('../pages/analytics/purchase-log'));

// handle auth and authorization
const PrivateRoute = inject('rootStore')(
  observer(({ component: Component, roles, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!rest.rootStore.auth.isLoggedIn) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
        }

        const loggedInUser = rest.rootStore.auth.user;
        // check if route is restricted by role
        if (roles && !loggedInUser.hasAnyRole(roles)) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  ))
);

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: (props) => {
    const isAdmin = props.rootStore.auth.user.isAdmin;
    const isMarketingAdmin = props.rootStore.auth.user.isMarketingAdmin;
    if (isAdmin || isMarketingAdmin) {
      return <Redirect to="/dashboard" />;
    } else {
      return <Redirect to="/village" />;
    }
  },
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  icon: FeatherIcon.Home,
  header: 'Navigation',
  component: Dashboard,
  roles: ['admin', 'village_moderator', 'marketing_admin'],
  route: PrivateRoute,
};

const villageRoutes = [
  {
    path: '/village/:villageID?/:modStatus?/:postID?',
    name: 'The Village',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    component: VillageHome,
    roles: ['admin', 'village_moderator', 'village_moderator_manager'],
    route: PrivateRoute,
    exact: false,
  },
  {
    path: '/profile',
    name: 'My Profile',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    component: VillageMyProfile,
    roles: ['village_moderator', 'admin', 'marketing_admin'],
    route: PrivateRoute,
  },
  {
    path: '/my-stats',
    name: 'My Stats',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    component: VillageMyStats,
    roles: ['village_moderator'],
    route: PrivateRoute,
  },
];

const activityLogRoutes = {
  path: '/activity-log',
  name: 'ActivityLog',
  icon: FeatherIcon.Home,
  header: 'Navigation',
  component: ActivityLog,
  roles: ['admin'],
  route: PrivateRoute,
};

const analyticsRoutes = [
  {
    path: '/analytics/users',
    name: 'Analytics: Users',
    icon: FeatherIcon.Users,
    header: 'Navigation',
    component: AnalyticsUsers,
    roles: ['admin', 'marketing_admin'],
    route: PrivateRoute,
    exact: false,
  },
  {
    path: '/analytics/sales',
    name: 'Analytics: Sales',
    icon: FeatherIcon.DollarSign,
    header: 'Navigation',
    component: AnalyticsSales,
    roles: ['admin', 'marketing_admin'],
    route: PrivateRoute,
    exact: false,
  },
  {
    path: '/analytics/villages/:villageID?',
    name: 'Analytics: The Village',
    icon: FeatherIcon.MessageSquare,
    header: 'Navigation',
    component: AnalyticsVillages,
    roles: ['admin', 'marketing_admin', 'village_moderator_manager'],
    route: PrivateRoute,
    exact: false,
  },
  {
    path: '/analytics/consultants/:consultantID?',
    name: 'Analytics: Consultants',
    icon: FeatherIcon.Users,
    header: 'Navigation',
    component: AnalyticsConsultants,
    roles: ['admin', 'village_moderator_manager'],
    route: PrivateRoute,
    exact: false,
  },
  {
    path: '/analytics/purchase-log',
    name: 'Global Purchase Log',
    icon: FeatherIcon.DollarSign,
    header: 'Navigation',
    component: PurchaseLog,
    roles: ['admin', 'super_admin'],
    route: PrivateRoute,
    exact: false,
  },
];

const programRoutes = [
  {
    path: '/lessons',
    name: 'Lessons',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: Programs,
    roles: ['admin', 'village_moderator', 'marketing_admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/lessons/:programID',
    name: 'Edit program',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: ProgramView,
    roles: ['admin', 'village_moderator', 'marketing_admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/lessons/:programID/new-article',
    name: 'New program article',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: CreateArticleView,
    roles: ['admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/programs/:programID/dev-editor',
    name: 'Dev editor',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: DevEditorView,
    roles: ['admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/lessons/:programID/articles/:articleID',
    name: 'New program article',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: EditArticleView,
    roles: ['admin', 'village_moderator', 'marketing_admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/article-schedule-pdf/:articleID',
    name: 'article-schedule-pdf',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: ArticleSchedulePDF,
    roles: ['admin'],
    route: PrivateRoute,
    exact: true,
  },
];

const userRoutes = [
  {
    path: '/users',
    name: 'Users',
    icon: FeatherIcon.Users,
    header: 'Navigation',
    component: Users,
    roles: ['admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/users/:userID',
    name: 'View user',
    icon: FeatherIcon.User,
    header: 'Navigation',
    component: UserView,
    roles: ['admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/user/create',
    name: 'Create user',
    icon: FeatherIcon.User,
    header: 'Navigation',
    component: CreateUser,
    roles: ['admin'],
    route: PrivateRoute,
    exact: true,
  },
];

const shopifyCustomersRouters = [
  {
    path: '/shopify-customers/:customerID',
    name: 'View shopify customers',
    icon: FeatherIcon.User,
    header: 'Navigation',
    component: ShopifyCustomerView,
    roles: ['admin'],
    route: PrivateRoute,
    exact: true,
  },
];

const eventRoutes = {
  path: '/events',
  name: 'Events',
  icon: FeatherIcon.Clock,
  header: 'Navigation',
  component: Events,
  roles: ['admin', 'village_moderator', 'marketing_admin'],
  route: PrivateRoute,
};

const settingsRoutes = {
  path: '/settings',
  name: 'Settings',
  icon: FeatherIcon.Settings,
  header: 'Navigation',
  component: Settings,
  roles: ['admin'],
  route: PrivateRoute,
};

const contentRoutes = [
  {
    path: '/content',
    name: 'Content',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: Content,
    roles: ['admin', 'marketing_admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/content/new-content',
    name: 'Content',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: ContentCreate,
    roles: ['admin', 'marketing_admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/content/:contentID',
    name: 'Edit content',
    icon: FeatherIcon.FileText,
    header: 'Navigation',
    component: ContentEdit,
    roles: ['admin', 'marketing_admin'],
    route: PrivateRoute,
    exact: true,
  },
  {
    path: '/products',
    name: 'Products',
    icon: FeatherIcon.Rss,
    header: 'Navigation',
    component: ProductsPage,
    roles: ['admin', 'marketing_admin'],
    route: PrivateRoute,
    exact: true,
  },
];

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
let allRoutes = [
  rootRoute,
  authRoutes,
  {
    path: '/schedule-pdf',
    name: 'Schedule PDF',
    component: SchedulePDF,
    route: Route,
  },
];

let authProtectedRoutes = [
  activityLogRoutes,
  dashboardRoutes,
  eventRoutes,
  {
    path: '/publish-content',
    name: 'Publish content',
    icon: FeatherIcon.Clock,
    header: 'Navigation',
    component: PublishContent,
    roles: ['admin'],
    route: PrivateRoute,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    icon: FeatherIcon.MessageCircle,
    header: 'Navigation',
    component: Notifications,
    roles: ['admin'],
    route: PrivateRoute,
  },
  settingsRoutes,
];
authProtectedRoutes = authProtectedRoutes
  .concat(programRoutes)
  .concat(userRoutes)
  .concat(shopifyCustomersRouters)
  .concat(contentRoutes)
  .concat(analyticsRoutes)
  .concat(villageRoutes);
allRoutes = allRoutes.concat(authProtectedRoutes);

const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
