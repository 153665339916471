import { types, flow, getParent } from 'mobx-state-tree';
import Content from './Content';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';

const db = firebase.firestore();

export default types
  .model('ContentManager', {
    contents: types.array(Content),
  })
  .views((self) => ({
    get drafts() {
      return getParent(self).drafts;
    },
    get empty() {
      return self.contents.length === 0;
    },
    get loading() {
      return self.empty;
    },
    get sortedContents() {
      const sorted = [...self.contents];
      return sorted.sort((a, b) => b.updated.date - a.updated.date);
    },
    get(id) {
      return self.contents.find((content) => content.id === id);
    },
  }))
  .volatile((self) => ({}))
  .actions((self) => ({
    pushContent(content) {
      self.contents.push(content);
      return self.contents[self.contents.length - 1];
    },
    load: flow(function* () {
      yield self.drafts.ensureListeningUnpublished();
      let query = db.collection('content').orderBy('updated', 'desc');
      const contentData = yield query.get();
      self.contents = self.drafts.replaceData('other-content', snapToArray(contentData));
    }),
    removeContent(contentID) {
      const index = self.contents.findIndex((a) => a.id === contentID);
      if (index >= 0) {
        let arr = self.contents.slice(0);
        arr.splice(index, 1);
        self.contents = arr;
      }
    },
  }));
