import { types } from 'mobx-state-tree';
import Timestamp from './Timestamp';

export default types
  .model('SubLogItem', {
    id: '',
    platform: '',
    uid: types.maybeNull(types.string),
    msg: '',
    data: '',
    time: Timestamp,
    email: types.maybe(types.string),
  })
  .views((self) => ({
    get extras() {
      let data = {};
      let prefixText = '';
      try {
        data = JSON.parse(self.data);
      } catch (err) {
        data = {};
      }
      let expires = null;
      let product_id = '';
      let auto_renew_status = '';
      if (self.platform === 'stripe') {
        let sub = data && data.data && data.data.object;
        if (!sub) return '';
        if (sub.current_period_end) {
          expires = new Date(sub.current_period_end * 1000).toISOString();
        }
        if (sub.items && sub.items.data[0]) {
          product_id = sub.items.data[0].plan.id;
        }
        if (sub.canceled_at && sub.cancel_at) {
          prefixText = `Cancelled (subscription will end: ${new Date(sub.cancel_at * 1000).toISOString()})`;
        }
      }
      if (self.platform === 'appStore') {
        let receipt = data.unified_receipt && data.unified_receipt.latest_receipt_info[0];
        if (receipt) {
          if (data.auto_renew_status) {
            auto_renew_status = data.auto_renew_status;
          }
          if (receipt.product_id) {
            product_id = receipt.product_id;
          }
          if (receipt.expires_date_ms) {
            expires = new Date(Number(receipt.expires_date_ms)).toISOString();
          }
        }
      }
      if (self.platform === 'playStore') {
        if (data && data.subscriptionId) {
          product_id = data.subscriptionId;
        }
      }
      if (
        !self.platform &&
        (self.msg === 'Added subscription features' || self.msg === 'Removed subscription features')
      ) {
        product_id = data.packageID;
      }

      let txt = [];
      if (prefixText) {
        txt.push(prefixText);
      }
      if (product_id) {
        txt.push(`PID: ${product_id}`);
      }
      if (expires) {
        txt.push(`Expires: ${expires}`);
      }
      if (auto_renew_status !== '') {
        txt.push(`Auto renew: ${auto_renew_status}`);
      }
      return txt.join(', ');
    },
  }));
