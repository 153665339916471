import { types, flow, getRoot } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import algolia from './../helpers/algolia';
import Post from './Post';
/**
    author_uid: 'abc',
    created: '2020-06-20,2020-08-30',
    modAssignedTo: 'abc'
 */
const db = firebase.firestore();

const PostManagerFilters = types
  .model('PostManagerFilters', {
    villageId: types.optional(types.string, ''),
    modStatus: types.optional(types.string, 'new'),
    author_uid: types.optional(types.string, ''),
    created: types.optional(types.string, ''),
    modAssignedTo: types.optional(types.string, ''),
  })
  .views((self) => ({
    get createdDateRangeArray() {
      let tmp = self.created.split(',');
      let from = tmp[0] ? new Date(tmp[0]) : null;
      let to = tmp[1] ? new Date(tmp[1]) : null;
      return [from, to];
    },
  }))
  .actions((self) => ({
    set(key, value) {
      if (typeof key === 'object') {
        for (let k of Object.keys(key)) {
          self[k] = key[k];
        }
        return;
      }
      self[key] = value;
    },
    reset() {
      self.villageId = '';
      self.modStatus = 'new';
      self.author_uid = '';
      self.created = '';
      self.modAssignedTo = '';
    },
  }));

export default types
  .model('PostManager', {
    search: '',
    sort: 'asc',
    filters: types.optional(PostManagerFilters, {}),
    openedID: '',
    items: types.array(Post),
    total: 0,
    perPage: 20,
    page: 0,
    pages: 1,
  })
  .views((self) => ({
    get query() {
      const filters = self.filters;
      const qf = [];

      if (filters.villageId) {
        qf.push('villageId:' + filters.villageId);
      }
      if (filters.modStatus) {
        qf.push('modStatus:' + filters.modStatus);
      }
      if (filters.author_uid) {
        qf.push('author_uid:' + filters.author_uid);
      }
      if (filters.modAssignedTo) {
        if (filters.modAssignedTo === 'UNASSIGNED') {
          qf.push('modAssigned:false');
        } else {
          qf.push('modAssignedTo:' + filters.modAssignedTo);
        }
      }
      if (filters.created) {
        let dates = filters.createdDateRangeArray;
        if (dates[0]) {
          qf.push('created._seconds > ' + Math.round(dates[0].getTime() / 1000));
        }
        if (dates[1]) {
          qf.push('created._seconds < ' + Math.round(dates[1].getTime() / 1000));
        }
      }

      return {
        filters: qf.join(' AND '),
        hitsPerPage: self.perPage,
      };
    },
    get sorted() {
      const sort = self.sort;
      const items = self.items.slice(0).filter((item) => {
        if (self.filters.modAssignedTo) {
          if (self.filters.modAssignedTo === 'UNASSIGNED' && item.modAssigned !== false) {
            return false;
          } else if (
            self.filters.modAssignedTo !== 'UNASSIGNED' &&
            (!item.modAssignedTo || !item.modAssignedTo.includes(self.filters.modAssignedTo))
          ) {
            return false;
          }
        }
        if (self.filters.modStatus && item.modStatus !== self.filters.modStatus) {
          return false;
        } else {
          return true;
        }
      });
      if (self.isSortedByStatusUpdate) {
        items.sort((a, b) => {
          if (!a.modStatusUpdated || !b.modStatusUpdated) return 0;
          return sort === 'asc'
            ? a.modStatusUpdated.milliseconds - b.modStatusUpdated.milliseconds
            : b.modStatusUpdated.milliseconds - a.modStatusUpdated.milliseconds;
        });
      } else {
        items.sort((a, b) => {
          return sort === 'asc'
            ? a.created.milliseconds - b.created.milliseconds
            : b.created.milliseconds - a.created.milliseconds;
        });
      }
      return items;
    },
    get last() {
      let sorted = self.sorted;
      return sorted[sorted.length - 1];
    },
    get hasMore() {
      return self.page + 1 < self.pages;
    },
    get opened() {
      if (!self.openedID) {
        return null;
      } else {
        return self.items.find((item) => item.id === self.openedID);
      }
    },
    get isSortedByStatusUpdate() {
      return ['newReply', 'inProgress'].includes(self.filters.modStatus);
    },
    get totalCount() {
      return self.isSortedByStatusUpdate ? self.sorted.length : self.total + (self.sorted.length - self.items.length);
    },
    get collection() {
      return getRoot(self).posts;
    },
  }))
  .volatile((self) => ({
    listeners: [],
    loadingMore: false,
    loading: false,
    refreshing: false,
    loaded: false,
  }))
  .actions((self) => {
    return {
      setTotal(val) {
        self.total = val;
      },
      open(postID) {
        self.openedID = postID;
      },
      close() {
        self.openedID = '';
      },
      mergePosts(posts) {
        self.page = 0;
        self.pages = 1;
        self.total = posts.length;
        for (let post of posts) {
          let existing = self.items.find((p) => p.id === post.id);
          if (existing) {
            console.log('update', post);
            existing.update(post);
          } else {
            self.items.push(post);
          }
        }
      },
      refresh: flow(function* () {
        if (self.refreshing) return;
        self.refreshing = true;
        let index = self.sort === 'asc' ? algolia.posts_asc : algolia.posts_desc;
        let results = yield index.search(self.search, self.query);
        self.pages = results.nbPages;
        self.total = results.nbHits;
        const items = results.hits
          .filter((post) => post.objectID)
          .map((post) => {
            post = Object.assign({}, post);
            post.id = post.objectID;
            if (typeof post.frozen !== 'boolean') {
              post.frozen = post.frozen === 'true';
            }
            return post;
          });
        for (let item of items) {
          let existing = self.items.find((it) => it.id === item.id);
          if (!existing) {
            self.loadOne(item.id);
          }
        }
        self.refreshing = false;
      }),
      loadOne: flow(function* (id) {
        let existing = self.items.find((it) => it.id === id);
        if (!existing) {
          const doc = yield db.collection('posts').doc(id).get();
          existing = self.items.find((it) => it.id === id);

          if (!existing) {
            self.items.push(
              Object.assign({}, doc.data(), {
                id: doc.id,
              })
            );
          }

          if (doc.exists) {
            existing = self.items.find((it) => it.id === id);
            existing.load();
          }
        } else {
          existing.load();
        }
      }),
      load: flow(function* () {
        self.unload(true);
        self.loading = true;
        if (self.isSortedByStatusUpdate) {
          let q = db.collection('posts').where('modStatus', '==', self.filters.modStatus);
          if (self.filters.modAssignedTo) {
            if (self.filters.modAssignedTo === 'UNASSIGNED') {
              q = q.where('modAssigned', '==', false);
            } else {
              q = q.where('modAssignedTo', 'array-contains', self.filters.modAssignedTo);
            }
          }
          q.onSnapshot((result) => {
            const filtered = result.docs.filter((snap) => {
              let valid = true;
              if (self.filters.villageId) {
                valid = valid && snap.get('villageId') === self.filters.villageId;
              }
              return valid;
            });
            self.mergePosts(
              filtered.map((snap) => {
                let post = Object.assign({}, snap.data(), { id: snap.id });
                return post;
              })
            );
          });
        } else {
          let index = self.sort === 'asc' ? algolia.posts_asc : algolia.posts_desc;
          let results = yield index.search(self.search, self.query);
          self.page = results.page;
          self.pages = results.nbPages;
          self.total = results.nbHits;
          self.items = results.hits
            .filter((post) => post.objectID)
            .map((post) => {
              post = Object.assign({}, post);
              post.id = post.objectID;
              if (typeof post.frozen !== 'boolean') {
                post.frozen = post.frozen === 'true';
              }
              return post;
            });
          for (let item of self.items) {
            item.load();
          }
        }
        self.loading = false;
        self.loaded = true;
      }),
      loadMore: flow(function* () {
        if (!self.hasMore || self.loadingMore || self.isSortedByStatusUpdate) return;
        self.loadingMore = true;
        const index = self.sort === 'asc' ? algolia.posts_asc : algolia.posts_desc;
        const results = yield index.search(
          self.search,
          Object.assign({}, self.query, {
            page: self.page + 1,
          })
        );
        self.page = results.page;
        self.pages = results.nbPages;
        const posts = results.hits
          .filter((post) => post.objectID)
          .map((post) => {
            post.id = post.objectID;
            if (typeof post.frozen !== 'boolean') {
              post.frozen = post.frozen === 'true';
            }
            return post;
          });
        for (let post of posts) {
          if (!self.items.find((it) => it.id === post.id)) {
            self.items.push(post);
          }
        }
        for (let item of self.items) {
          item.load();
        }
        self.loadingMore = false;
      }),
      setSearch(keywords, noload) {
        self.search = keywords;
        if (!noload) {
          let village = self.filters.villageId;
          self.filters.reset();
          self.filters.set('modStatus', '');
          self.filters.set('villageId', village);
          self.load();
        }
      },
      setSort(val, noload) {
        self.sort = val;
        if (!noload) {
          self.load();
        }
      },
      setFilter(key, value, noload) {
        self.filters.set(key, value);
        if (!noload) {
          self.load();
        }
      },
      loadAllFromUser(uid) {
        self.filters.reset();
        self.filters.set('modStatus', '');
        self.filters.set('author_uid', uid);
        self.load();
      },
      unload(keepFilters) {
        for (let unsubscribe of self.listeners) {
          unsubscribe();
        }
        self.loaded = false;
        self.loading = false;
        self.listeners = [];
        for (let item of self.items) {
          item.unload();
        }
        self.items = [];
        self.total = 0;
        if (!keepFilters) {
          self.filters.reset();
        }
      },
      remove(id) {
        const index = self.items.findIndex((item) => item.id === id);
        if (index >= 0) {
          self.items.splice(index, 1);
        }
      },
    };
  });
