import { types, getSnapshot } from 'mobx-state-tree';
import Alert from './Alert';

const AlertManager = types
  .model('AlertManager', {
    current: types.optional(types.maybeNull(Alert), null),
    queue: types.array(Alert),
  })
  .actions((self) => ({
    push(data) {
      self.queue.push(data);
    },
    pull() {
      const alert = self.queue.pop();
      if (alert) {
        self.current = Object.assign({}, getSnapshot(alert));
      } else {
        self.current = null;
      }
    },
    set(data) {
      self.current = data;
    },
  }));

export default AlertManager;
