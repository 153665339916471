import { types, flow, getParent } from 'mobx-state-tree';
import Program from './Program';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';

const db = firebase.firestore();

export default types
  .model('ProgramManager', {
    programs: types.array(Program),
  })
  .views((self) => ({
    get drafts() {
      return getParent(self).drafts;
    },
    get empty() {
      return self.programs.length === 0;
    },
    get loading() {
      return self.empty;
    },
    fromCategory(category) {
      const programs = self.programs.filter((program) => program.category === category);
      const sortedPrograms = programs.sort((a, b) => a.name.localeCompare(b.name));

      return sortedPrograms;
    },
    get(id) {
      return self.programs.find((program) => program.id === id);
    },
  }))
  .volatile((self) => ({}))
  .actions((self) => ({
    load: flow(function* () {
      yield self.drafts.ensureListeningUnpublished();
      const programData = yield db.collection('programs').orderBy('sortIndex', 'asc').get();
      self.programs = self.drafts.replaceData('program', snapToArray(programData));
    }),
  }));
