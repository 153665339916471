import { types } from 'mobx-state-tree';
import Metric from './Metric';

export default types
  .model('MetricCollection', {
    items: types.array(Metric),
  })
  .volatile((self) => ({}))
  .views((self) => ({
    getGraphSeries(metric, days) {
      days = days || 30;
      let start = new Date();
      start.setDate(start.getDate() - days);
      start = start.toISOString().split('T').shift();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      endDate = endDate.toISOString().split('T').shift();
      const items = self.items.filter(
        (m) => m.metric === metric && m.timePeriod === 'day' && m.time >= start && m.time <= endDate
      );
      return items.map((m) => ({
        x: m.time,
        y: m.value / (m.count || 1),
      }));
    },
    getGraphSeriesNotTime(metric, sortFunc) {
      const items = self.items.filter((m) => m.metric === metric);
      const series = items.map((m) => ({
        x: m.time,
        y: m.value,
      }));
      series.sort(sortFunc);
      return series;
    },
    getByMetricTime(metric, xVal) {
      return self.items.find((m) => m.metric === metric && m.time === xVal);
    },
    getGraphLabels(metric) {
      const items = self.items.filter((m) => m.metric === metric && m.timePeriod === 'day');
      return items.map((item) => {
        let tmp = item.time.split('-').map((val) => parseInt(val, 10));
        let d = new Date(tmp[0], tmp[1] - 1, tmp[2]);
        return d.getDate() + ' ' + d.toLocaleString('en-us', { month: 'short' });
      });
    },
    getNowMetric(name) {
      return self.items.find((m) => m.metric === name);
    },
    getCombinedMetric(period, name, mode, days) {
      days = days || 30;
      let start = new Date();
      start.setDate(start.getDate() - days);
      start = start.toISOString().split('T').shift();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      endDate = endDate.toISOString().split('T').shift();
      const items = self.items.filter(
        (metric) =>
          metric.metric === name && metric.timePeriod === period && metric.time >= start && metric.time <= endDate
      );
      if (items.length === 0) {
        return 0;
      }
      if (mode === 'sum') {
        return items.reduce((m, metric) => {
          return m + metric.value;
        }, 0);
      } else if (mode === 'avg') {
        let total = 0;
        let sum = items.reduce((m, metric) => {
          total += metric.count || 1;
          return m + metric.value;
        }, 0);
        return sum / total;
      }
    },
  }))
  .actions((self) => ({
    merge(items) {
      for (let item of items) {
        let existing = self.items.find((it) =>
          item.id ? it.id === item.id : it.metric === item.metric && it.time === item.time
        );
        if (existing) {
          existing.update(item.value, item.count);
        } else {
          self.items.push(item);
        }
      }
      self.items.replace(
        self.items.slice().sort((a, b) => {
          if (a.time > b.time) {
            return 1;
          } else if (a.time < b.time) {
            return -1;
          } else {
            return 0;
          }
        })
      );
    },
    setEmptyValues(days) {
      days = days || 30;
      let start = new Date();
      start.setDate(start.getDate() - days);
      start = start.toISOString().split('T').shift();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);

      const dayMetrics = self.items.filter((item) => item.timePeriod === 'day');
      const METRIC_TYPES = [];
      for (let m of dayMetrics) {
        if (!METRIC_TYPES.includes(m.metric)) {
          METRIC_TYPES.push(m.metric);
        }
      }

      const dates = [];
      for (let d = new Date(start); d < endDate; d.setDate(d.getDate() + 1)) {
        let tString = d.toISOString().split('T').shift();
        dates.push(tString);
      }
      for (let mtype of METRIC_TYPES) {
        for (let date of dates) {
          if (!dayMetrics.find((m) => m.metric === mtype && m.time === date)) {
            dayMetrics.push({
              id: '',
              objectType: 'purchase',
              objectID: '',
              metric: mtype,
              time: date,
              timePeriod: 'day',
              value: 0,
              count: 0,
            });
          }
        }
      }

      self.merge(dayMetrics);
    },
  }));
