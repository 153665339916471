import { flow, types } from 'mobx-state-tree';
import firebase from 'helpers/firebase';
import Event from './Event';
import eventUtil from 'helpers/events';

const EventSchedule = types.model('EventSchedule', {
  loading: false,
  loaded: false,
  schedule: types.array(
    types.compose('Event', Event, types.model({ id: types.string, adjustedStartFrom: '', adjustedTimeReason: '' }))
  ),
});

export default types
  .model('EventSchedulePreview', {
    currentWeeksAge: 2,
    currentDailyStart: '07:00',
    currentSleepTimes: types.array(
      types.model({
        wakeEventId: types.string,
        startTime: types.string,
        endTime: types.string,
        skipped: types.optional(types.boolean, false),
      })
    ),
    currentSchedulePreview: types.optional(
      types.model({
        default: types.optional(EventSchedule, {}),
        adjusted: types.optional(EventSchedule, {}),
      }),
      {}
    ),
  })
  .views((self) => ({
    get httpsEventSchedulePreview() {
      return firebase.functions().httpsCallable('httpsEventSchedulePreview');
    },
    get dailyStartOffsetMinutes() {
      const DEFAULT_DAILY_START = '07:00';

      const defaultDailyStartInMinutes = eventUtil.getMinutesFromTimeString(DEFAULT_DAILY_START);
      const currentDailyStartInMinutes = eventUtil.getMinutesFromTimeString(self.currentDailyStart);

      return currentDailyStartInMinutes - defaultDailyStartInMinutes;
    },
  }))
  .actions((self) => ({
    loadCurrentDefaultSchedulePreview: flow(function* () {
      try {
        self.currentSchedulePreview.default.loaded = false;
        self.currentSchedulePreview.default.loading = true;
        const result = yield self.httpsEventSchedulePreview({
          dailyStartOffset: self.dailyStartOffsetMinutes,
          weeksAge: self.currentWeeksAge,
        });

        const sortedSchedule = (result.data.schedule || []).sort((a, b) => {
          const AstartForm = a.adjustedStartFrom || a.startFrom;
          const BstartForm = b.adjustedStartFrom || b.startFrom;

          if (AstartForm < BstartForm) {
            return -1;
          }
          if (AstartForm > BstartForm) {
            return 1;
          }
          return 0;
        });

        self.currentSchedulePreview.default.schedule = sortedSchedule;
        self.currentSleepTimes = self.getSleepTimesFromSchedule(self.currentSchedulePreview.default.schedule);

        for (const event of self.currentSchedulePreview.default.schedule) {
          if (event.troubleshootingTips.length < 1) continue;

          const troubleshootingTipId = event.troubleshootingTips[0].id;
          const troubleshootingTip = (result?.data?.tips || []).find((tip) => tip.id === troubleshootingTipId);

          if (troubleshootingTip && troubleshootingTip.content) {
            event.tipsContent = troubleshootingTip.content;
          }
        }

        self.currentSchedulePreview.default.loaded = true;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        self.currentSchedulePreview.default.loading = false;
      }
    }),
    loadCurrentAdjustedSchedulePreview: flow(function* () {
      try {
        self.currentSchedulePreview.adjusted.loaded = false;
        self.currentSchedulePreview.adjusted.loading = true;
        const result = yield self.httpsEventSchedulePreview({
          dailyStartOffset: self.dailyStartOffsetMinutes,
          weeksAge: self.currentWeeksAge,
          sleepTimes: self.currentSleepTimes.map((sleepTime) => ({
            startTime: sleepTime.startTime,
            endTime: sleepTime.endTime,
            skipped: sleepTime.skipped,
          })),
        });

        const sortedSchedule = (result.data.schedule || []).sort((a, b) => {
          const AstartForm = a.adjustedStartFrom || a.startFrom;
          const BstartForm = b.adjustedStartFrom || b.startFrom;

          if (AstartForm < BstartForm) {
            return -1;
          }
          if (AstartForm > BstartForm) {
            return 1;
          }
          return 0;
        });

        self.currentSchedulePreview.adjusted.schedule = sortedSchedule;

        for (const event of self.currentSchedulePreview.adjusted.schedule) {
          if (event.troubleshootingTips.length < 1) continue;

          const troubleshootingTipId = event.troubleshootingTips[0].id;
          const troubleshootingTip = (result?.data?.tips || []).find((tip) => tip.id === troubleshootingTipId);

          if (troubleshootingTip && troubleshootingTip.content) {
            event.tipsContent = troubleshootingTip.content;
          }
        }

        self.currentSchedulePreview.adjusted.loaded = true;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        self.currentSchedulePreview.adjusted.loading = false;
      }
    }),
    getSleepTimesFromSchedule(schedule) {
      const sleepTimes = [];

      const firstSleepIndex = schedule.findIndex((event) => event.typeId === 'sleep' && !event.hidden);
      const firstSleep = schedule[firstSleepIndex];

      if (!firstSleep) return sleepTimes;

      let lastSleepEvent = firstSleep;

      let i = firstSleepIndex;
      do {
        i++;

        if (i >= schedule.length) {
          i = 0;
        }

        const event = schedule[i];

        // if (event.hidden) {
        //   continue;
        // }

        if (event.typeId === 'sleep') {
          lastSleepEvent = event;
        }

        if (event.typeId === 'wake' && event.name !== 'Morning Start') {
          let sleepTime;
          // using .find cause this warnings: Function declared in a loop contains unsafe references to variable(s) 'lastSleepEvent'
          for (const sp of sleepTimes) {
            if (sp.startTime === lastSleepEvent) {
              sleepTime = sp;
              break;
            }
          }

          if (sleepTime) {
            sleepTime.endTime = event.startFrom;
            sleepTime.wakeEventId = event.id;
          } else {
            sleepTimes.push({
              startTime: lastSleepEvent.startFrom,
              endTime: event.startFrom,
              wakeEventId: event.id,
            });
          }
        }
      } while (i !== firstSleepIndex);

      return sleepTimes.sort(
        (a, b) => eventUtil.getMinutesFromTimeString(a.endTime) - eventUtil.getMinutesFromTimeString(b.endTime)
      );
    },
    setCurrentWeeksAge: flow(function* (weeksAge) {
      if (self.currentWeeksAge === weeksAge) return;

      self.currentWeeksAge = weeksAge;
      yield self.loadCurrentDefaultSchedulePreview();
      yield self.loadCurrentAdjustedSchedulePreview();
    }),
    setCurrentDailyStart: flow(function* (dailyStart) {
      if (self.currentDailyStart === dailyStart) return;

      self.currentDailyStart = dailyStart;
      yield self.loadCurrentDefaultSchedulePreview();
      yield self.loadCurrentAdjustedSchedulePreview();
    }),
    setCurrentSleepTime(wakeEventId, { startTime, endTime, skipped }) {
      const sleepTime = self.currentSleepTimes.find((sleepTime) => sleepTime.wakeEventId === wakeEventId);

      if (!sleepTime) return;
      if (startTime === sleepTime.startTime && endTime === sleepTime.endTime && skipped === sleepTime.skipped) return;

      sleepTime.startTime = startTime;
      sleepTime.endTime = endTime;
      sleepTime.skipped = skipped;

      self.loadCurrentAdjustedSchedulePreview();
    },
  }));
