import { types, getSnapshot, flow, getParent } from 'mobx-state-tree';
import Timestamp, { MaybeTimestamp } from './Timestamp';

const ContentTrigger = types.model('ContentTrigger', {
  type: 'screen-view',
  limits: types.model({
    maxDisplays: types.maybe(types.number),
  }),
  data: types.model({
    path: types.maybe(
      types.enumeration(['/home', '/lessons', '/village', '/village/new-post', '/tracker', '/home/schedule'])
    ),
  }),
});

const ContentSchedule = types.model('ContentScheduleModel', {
  start: types.optional(MaybeTimestamp, null),
  stop: types.optional(MaybeTimestamp, null),
  timeZone: types.optional(types.string, 'Pacific/Auckland'),
});

const Content = types
  .model('Content', {
    id: types.string,
    oldId: '',
    title: types.string,
    content: '{"contents":[{ "p": "" }]}', // <string> content
    created: types.maybe(Timestamp), // <timestamp> when post was created
    updated: types.maybe(Timestamp),
    type: types.optional(types.enumeration(['popup', 'page']), 'page'),
    status: types.optional(types.enumeration(['active', 'draft', 'paused']), 'active'),
    trigger: types.optional(types.union(types.literal(null), types.literal(undefined), ContentTrigger), null),
    schedule: types.optional(types.union(types.literal(null), types.literal(undefined), ContentSchedule), {}),
    buttonLabel: 'Dismiss',
  })
  .volatile((self) => ({
    saving: false,
    deleting: false,
  }))
  .views((self) => ({}))
  .actions((self) => ({
    getDraft() {
      const drafts = getParent(self, 2).drafts;
      const existing = drafts.getUnpublishedByItemID(self.id);
      if (existing) {
        return existing;
      } else {
        // create the draft
        return drafts.createForItem('other-content', self.title, getSnapshot(self), 'edit');
      }
    },
    set(key, value) {
      const draft = self.getDraft();

      if (key.includes('.')) {
        let obj;
        let keys = key.split('.');
        let last = keys.pop();
        obj = keys.reduce((m, key) => {
          return m[key];
        }, self);
        obj[last] = value;
      } else {
        self[key] = value;
      }

      if (key === 'id') {
        draft.set('itemId', value);
      }
      draft.setItemLabel(self.title);
      draft.setItemData(key, value);
    },
    update: flow(function* (key, value) {
      self.saving = true;
      self[key] = value;
      self.getDraft().setItemLabel(self.title);
      yield self.getDraft().updateItemData(key, value);
      self.saving = false;
    }),
    save: flow(function* (params) {
      const { isNew } = params || {};

      self.saving = true;
      self.getDraft().setItemLabel(self.title);

      if (isNew) {
        self.getDraft().setOperation('create');
      }

      yield self.getDraft().save();
      self.saving = false;
    }),
    delete: flow(function* () {
      self.deleting = true;
      yield self.getDraft().deleteItem();
      getParent(self, 2).removeContent(self.id);
      self.deleting = false;
    }),
    getSnapshot() {
      return getSnapshot(self);
    },
  }));

export default Content;
