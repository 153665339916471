import { types } from 'mobx-state-tree';
import AlertManager from './UI/AlertManager';
import ModalManager from './UI/ModalManager';

const UI = types
  .model('UI', {
    alerts: types.optional(AlertManager, {}),
    modals: types.optional(ModalManager, {}),
  })
  .actions((self) => ({
    pageWillChange() {
      self.modals.close();
    },
  }));

export default UI;
