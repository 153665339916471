import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function createdAgo(date) {
  const time = date.getTime();
  const now = new Date().getTime();
  const diff = Math.round((now - time) / 1000);
  let str = '';
  let n = -1;
  if (diff < 60) {
    str = 'Just now';
  } else if (diff < 3600) {
    n = Math.round(diff / 60);
    str = `${n} min${n > 1 ? 's' : ''} ago`;
  } else if (diff < 3600 * 24) {
    n = Math.round(diff / 3600);
    str = `${n} hour${n > 1 ? 's' : ''} ago`;
  } else if (diff < 3600 * 24 * 7) {
    n = Math.round(diff / (3600 * 24));
    if (n === 1) {
      str = 'Yesterday';
    } else {
      str = `${n} days ago`;
    }
  } else if (diff < 3600 * 24 * 30) {
    n = Math.round(diff / (3600 * 24 * 7));
    str = `${n} week${n > 1 ? 's' : ''} ago`;
  } else if (diff < 3600 * 24 * 30 * 12) {
    n = Math.round(diff / (3600 * 24 * 30));
    str = `${n} month${n > 1 ? 's' : ''} ago`;
  } else {
    str = new Date(time).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
  }
  return str;
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const shortMonthNames = monthNames.map((name) => {
  return name.substr(0, 3);
});

export function DateFormat(props) {
  if (!props.timestamp) {
    return '/';
  }

  let date;
  if (props.timestamp.milliseconds) {
    date = new Date(props.timestamp.milliseconds);
  } else if (props.timestamp._seconds) {
    date = new Date(props.timestamp._seconds * 1000);
  } else {
    date = new Date(props.timestamp.toMillis ? props.timestamp.toMillis() : props.timestamp);
  }

  if (isNaN(date.getDate())) {
    console.log(props.timestamp);
  }

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  let str = '';
  if (props.shortDate) {
    str = day + '. ' + shortMonthNames[monthIndex];
  } else if (props.longDate) {
    str = day + '. ' + monthNames[monthIndex] + ' ' + year;
  } else {
    str = day + '. ' + shortMonthNames[monthIndex] + ' ' + year;
  }

  if (props.withTime) {
    let hours = date.getHours();
    if (hours < 10) {
      hours = '0' + hours;
    }
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    str = str + ' at ' + (hours + ':' + minutes);
  }

  return str;
}

export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function convertTimezone(date, originalTimezone, targetTimezone) {
  const utcDate = zonedTimeToUtc(date, originalTimezone);
  const targetDate = utcToZonedTime(utcDate, targetTimezone);

  return targetDate;
}
