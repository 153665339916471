import { types, flow, getParent } from 'mobx-state-tree';
import ContentDraft from './ContentDraft';
import firebase from './../helpers/firebase';
import { when } from 'mobx';

const db = firebase.firestore();

// const typeToCol = {
//   'program': 'programs',
//   'program-article': 'program_articles',
//   'event': 'events',
//   'schedule-milestone': 'schedule_milestones',
//   'other-content': 'content'
// };

export default types
  .model('ContentDraftManager', {
    unpublished: types.array(ContentDraft),
  })
  .views((self) => ({
    get thisUser() {
      return getParent(self).auth.user;
    },
    get unpublishedSaved() {
      return self.unpublished.filter((d) => d.saved);
    },
    getUnpublished(id) {
      return self.unpublished.find((draft) => draft.id === id);
    },
    getUnpublishedByType(type) {
      return self.unpublished.filter((draft) => draft.saved && draft.itemType === type);
    },
    getUnpublishedByItemID(itemID) {
      return self.unpublished.find((draft) => draft.itemId === itemID);
    },
  }))
  .volatile((self) => ({
    listening: 'off',
    publishing: false,
  }))
  .actions((self) => ({
    commit: flow(function* (draftIDs, message, toDevelopment) {
      try {
        self.publishing = true;
        const httpsAdminPublishContentDrafts = firebase.functions().httpsCallable('httpsAdminPublishContentDrafts');
        yield httpsAdminPublishContentDrafts({
          toDevelopment,
          message,
          draftIDs,
        });

        for (const draftId of draftIDs) {
          if (self.getUnpublished(draftId)) {
            self.removeUnpublished(draftId);
          }
        }

        self.publishing = false;
      } catch (error) {
        self.publishing = false;
        return Promise.reject(error);
      }
    }),
    createForItem(type, label, data, op) {
      const id = data.id;
      if (!id) throw new Error('Missing id!');
      let draft = {
        id: db.collection('content_drafts').doc().id,
        operation: op,
        itemLabel: label,
        itemType: type,
        itemId: id,
        itemData: data,
        changedBy: [self.thisUser.id],
      };
      let index = self.unpublished.length;
      self.unpublished.push(draft);
      draft = self.unpublished[index];
      return draft;
    },
    replaceData(itemType, dataFromDatabase) {
      let arr = dataFromDatabase
        .map((data) => {
          const draft = self.getUnpublishedByItemID(data.id);
          if (draft && draft.itemData) {
            return Object.assign(data, draft.itemData);
          } else if (draft && !draft.itemData) {
            return null;
          } else {
            // no draft, just return data
            return data;
          }
        })
        .filter((d) => d);
      // also, drafts that are a result of new item created
      for (let d of self.unpublished) {
        if (d.operation === 'create' && itemType === d.itemType) {
          arr.push(Object.assign({}, d.itemData, { id: d.itemId }));
        }
      }
      return arr;
    },
    addUnpublished(snap) {
      // add and listen for changes
      let index = self.unpublished.length;
      self.unpublished.push(
        Object.assign({}, snap.data(), {
          id: snap.id,
        })
      );
      let draft = self.unpublished[index];
      draft.setSaved(true);
      draft.listen();
    },
    removeUnpublished(id) {
      let draft = self.getUnpublished(id);
      if (!draft) return;
      draft.stopListening();
      let index = self.unpublished.indexOf(draft);
      self.unpublished.splice(index, 1);
    },
    ensureListeningUnpublished: flow(function* () {
      if (!self.thisUser.isAdmin && !self.thisUser.isMarketingAdmin) {
        return;
      }
      if (self.listening === 'starting') {
        yield when(() => self.listening === 'on');
        return;
      }
      if (self.listening === 'on') {
        return;
      }
      yield self.listenUnpublished();
      self.listening = 'on';
    }),
    listenUnpublished() {
      let called = false;
      return new Promise((resolve) => {
        db.collection('content_drafts')
          .where('commitId', '==', '')
          .onSnapshot((querySnap) => {
            for (let snap of querySnap.docs) {
              if (!self.getUnpublished(snap.id)) {
                self.addUnpublished(snap);
              }
            }
            if (!called) {
              resolve();
              called = true;
            }
          });
      });
    },
  }));
