import { types, flow, getParent } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
import { when } from 'mobx';
import Metric from './Metric';
import MetricSeriesCollection from './MetricSeriesCollection';

const METRIC_TYPES = [
  'onlineTime',
  'firstReplyCount',
  'subseqReplyCount',
  'commentCount',
  'appCommentCount',
  'timeToFirstReply',
  'timeToSubseqReply',
];

const db = firebase.firestore();

export default types
  .model('UserStatsManager', {
    nowMetrics: types.array(Metric),
    dayMetrics: types.array(Metric),
    series: types.optional(MetricSeriesCollection, {}),
  })
  .volatile((self) => ({
    loading: false,
    loaded: '',
  }))
  .views((self) => ({
    get user() {
      return getParent(self);
    },
    get totalOnlineTime() {
      return self.getCombinedMetric('day', 'onlineTime', 'sum', 30);
    },
    get totalCommentCount() {
      return self.getCombinedMetric('day', 'commentCount', 'sum', 30);
    },
    get totalAppCommentCount() {
      return self.getCombinedMetric('day', 'appCommentCount', 'sum', 30);
    },
    get avgReplyPerHour() {
      const replies = self.getCombinedMetric('day', 'commentCount', 'sum', 7);
      const repliesFromApp = self.getCombinedMetric('day', 'appCommentCount', 'sum', 7) || 0;
      const onlineTime = self.getCombinedMetric('day', 'onlineTime', 'sum', 7);
      if (!onlineTime || onlineTime < 1) {
        return 0;
      }
      return Number(((replies - repliesFromApp) / (onlineTime / 60)).toFixed(1));
    },
    get avgReplyPerHour30() {
      const replies = self.getCombinedMetric('day', 'commentCount', 'sum', 30);
      const repliesFromApp = self.getCombinedMetric('day', 'appCommentCount', 'sum', 30) || 0;
      const onlineTime = self.getCombinedMetric('day', 'onlineTime', 'sum', 30);
      if (!onlineTime || onlineTime < 1) {
        return 0;
      }
      return Number(((replies - repliesFromApp) / (onlineTime / 60)).toFixed(1));
    },
    get avgTimeToFirstReply() {
      const seconds = self.getCombinedMetric('day', 'timeToFirstReply', 'avg', 7);
      return seconds;
    },
    get avgTimeToSubseqReply() {
      const seconds = self.getCombinedMetric('day', 'timeToSubseqReply', 'avg', 7);
      return seconds;
    },
    get avgReplies() {
      return Math.round(self.getCombinedMetric('day', 'commentCount', 'avg', 30));
    },
    get totalReplyCount() {
      return self
        .getCombinedMetric('now', 'totalCommentCount', 'avg')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getGraphSeries(metric) {
      const items = self.dayMetrics.filter((m) => m.metric === metric);
      return items.map((item) => item.value);
    },
    getGraphLabels(metric) {
      const items = self.dayMetrics.filter((m) => m.metric === metric);
      return items.map((item) => {
        let tmp = item.time.split('-').map((val) => parseInt(val, 10));
        let d = new Date(tmp[0], tmp[1] - 1, tmp[2]);
        return d.getDate() + ' ' + d.toLocaleString('en-us', { month: 'short' });
      });
    },
    getGraphSeriesXY(metric) {
      const items = self.dayMetrics.filter((m) => m.metric === metric);

      // fix invisible lines in the chart
      items.splice(0, Math.max(0, items.length - 266));

      return items.map((item) => {
        let tmp = item.time.split('-').map((val) => parseInt(val, 10));
        let d = new Date(tmp[0], tmp[1] - 1, tmp[2]);
        return {
          y: item.value,
          x: d.getDate() + ' ' + d.toLocaleString('en-us', { month: 'short' }),
        };
      });
    },
    getCombinedMetric(period, name, mode, days) {
      let items = self[period + 'Metrics'].filter((metric) => metric.metric === name);
      if (days) {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - days);
        startDate = startDate.toISOString().split('T').shift();
        items = items.filter((item) => item.time >= startDate);
      }
      if (items.length === 0) {
        return 0;
      }
      if (mode === 'sum') {
        return items.reduce((m, metric) => {
          return m + metric.value;
        }, 0);
      } else if (mode === 'avg') {
        let sum = items.reduce((m, metric) => {
          return m + metric.value / (metric.count || 1);
        }, 0);
        return sum / items.length;
      }
    },
  }))
  .actions((self) => ({
    load: flow(function* (days) {
      const loadedValue = String(days || 'full');
      if (self.loading) {
        yield when(() => !self.loading && self.loaded === loadedValue);
        return;
      }
      if (self.loaded === loadedValue) {
        return;
      }
      self.loading = true;
      let start;
      if (days) {
        start = new Date();
        start.setDate(start.getDate() - days);
        start = start.toISOString().split('T').shift();
      } else {
        start = new Date();
        start.setFullYear(start.getFullYear() - 1);
        start = start.toISOString().split('T').shift();
      }
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);

      const results = yield Promise.all([
        db
          .collection('metrics')
          .where('objectType', '==', 'user')
          .where('objectID', '==', self.user.id)
          .where('timePeriod', '==', 'now')
          .get(),
        db
          .collection('metrics')
          .where('objectType', '==', 'user')
          .where('objectID', '==', self.user.id)
          .where('timePeriod', '==', 'day')
          .where('time', '>=', start)
          .where('time', '<=', endDate.toISOString().split('T').shift())
          .orderBy('time', 'asc')
          .get(),
      ]);
      self.nowMetrics = snapToArray(results[0]);

      const dayMetrics = snapToArray(results[1]);

      // Fill in dates with empty values
      const dates = [];
      for (let d = new Date(start); d < endDate; d.setDate(d.getDate() + 1)) {
        let tString = d.toISOString().split('T').shift();
        dates.push(tString);
      }
      for (let mtype of METRIC_TYPES) {
        for (let date of dates) {
          let id = `user_${self.user.id}_${mtype}_${date}`;
          if (!dayMetrics.find((m) => m.id === id)) {
            dayMetrics.push({
              id: id,
              objectType: 'user',
              objectID: self.user.id,
              metric: mtype,
              time: date,
              timePeriod: 'day',
              value: 0,
              count: 0,
            });
          }
        }
      }

      dayMetrics.sort((a, b) => {
        if (a.time > b.time) {
          return 1;
        } else if (a.time < b.time) {
          return -1;
        } else {
          return 0;
        }
      });

      self.dayMetrics = dayMetrics;

      self.loading = false;
      self.loaded = loadedValue;
    }),
  }));
