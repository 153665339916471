import { types, getParentOfType, getParent, getSnapshot, flow } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import EventManager from './EventManager';
import ssevents from './../helpers/events';

const db = firebase.firestore();

const EventNotification = types.model('EventNotification', {
  title: '', // <string> title of the notification i.e. Morning nap
  body: '', // <string> body of the notification i.e. “Your baby needs to take the morning nap soon!”
  popStartOffset: 0, // <int> Offset in minutes, by default 0, from the start when to show this notification to the user based on events start time. If negative, it means before the start of event, if positive, it means after the start of event
});

const TroubleshootingRef = types.model('TroubleshootingTipReference', {
  id: types.string,
});

const EventLogicCondition = types
  .model('EventLogicCondition', {
    if: types.array(
      types.model({
        conditions: types.array(
          types.model({
            variable: types.union(
              types.literal(''),
              types.literal('wakeUpTime'),
              types.literal('wakeUpOffset'),
              types.literal('sleepTime'),
              types.literal('sleepTimeOffset'),
              types.literal('awakeTime'),
              types.literal('awakeTimeOffset'),
              types.literal('fellAsleepTime'),
              types.literal('fellAsleepOffset'),
              types.literal('skippedSleepEvent')
            ),
            operator: types.union(
              types.literal(''),
              types.literal('<'),
              types.literal('<='),
              types.literal('=='),
              types.literal('>'),
              types.literal('>='),
              types.literal('!'),
              types.literal('true'),
              types.literal('false')
            ),
            val: '',
            eventIDs: types.array(types.string),
          })
        ),
      })
    ),
    then: types.array(
      types.model({
        subject: 'events',
        eventIDs: types.array(types.string),
        modifier: types.union(
          types.literal(''),
          types.literal('setStartTime'),
          types.literal('startTimeOffset'),
          types.literal('limitSleepTimeLength'),
          types.literal('setDistanceFromWakeTime'),
          types.literal('startTimeOffsetFromEvent'),
          types.literal('setEventVisibility')
        ),
        val: '',
        val2: '',
      })
    ),
    label: '',
  })
  .actions((self) => ({
    addCondition(conditionBlockIndex, condition = { variable: '', operator: '', val: '', eventIDs: [] }) {
      if (!self.if[conditionBlockIndex]) {
        throw new Error(`condition block with index ${conditionBlockIndex} is not exist`);
      }
      self.if[conditionBlockIndex].conditions.push(condition);
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    removeCondition(conditionBlockIndex, conditionIndex) {
      if (!self.if[conditionBlockIndex]) {
        throw new Error(`condition block with index ${conditionBlockIndex} is not exist`);
      }

      self.if[conditionBlockIndex].conditions.splice(conditionIndex, 1);
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    updateCondition(conditionBlockIndex, conditionIndex, newData) {
      if (!self.if[conditionBlockIndex]) {
        throw new Error(`condition block with index ${conditionBlockIndex} is not exist`);
      }

      if (!self.if[conditionBlockIndex].conditions[conditionIndex]) {
        throw new Error(`condition with index ${conditionIndex} is not exist`);
      }

      for (const key of Object.keys(newData)) {
        if (typeof newData[key] === 'undefined') continue;
        self.if[conditionBlockIndex].conditions[conditionIndex][key] = newData[key];
      }
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    addConditionBlock(conditionBlock = { conditions: [{ variable: '', operator: '', val: '' }] }) {
      self.if.push(conditionBlock);
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    removeConditionBlock(conditionBlockIndex) {
      self.if.splice(conditionBlockIndex, 1);
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    addAction(action = { subject: 'events', eventIDs: [], modifier: '', val: '' }) {
      self.then.push(action);
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    removeAction(actionIndex) {
      self.then.splice(actionIndex, 1);
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    updateAction(actionIndex, newData) {
      if (!self.then[actionIndex]) {
        throw new Error(`Action with index ${actionIndex} is not index`);
      }

      for (const key of Object.keys(newData)) {
        if (typeof newData[key] === 'undefined') continue;
        self.then[actionIndex][key] = newData[key];
      }
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
    setLabel(label) {
      self.label = label;
      getParent(self, 3).markChanged();
      getParent(self, 2).updateDraftItemData();
    },
  }));

const EventLogic = types
  .model('EventLogic', {
    conditions: types.array(EventLogicCondition),
    disableExclusionWindow: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    addLogic(
      logic = {
        if: [{ conditions: [{ variable: '', operator: '', val: '' }] }],
        then: [{ subject: 'events', eventIDs: [], modifier: '' }],
      }
    ) {
      self.conditions.push(logic);
      getParent(self).markChanged();
      self.updateDraftItemData();
    },
    removeLogic(logicIndex) {
      self.conditions.splice(logicIndex, 1);
      getParent(self).markChanged();
      self.updateDraftItemData();
    },
    updateDraftItemData() {
      const event = getParent(self);
      event.getDraft().setItemData('eventLogic.conditions', event.eventLogic.conditions);
    },
  }));

export default types
  .model('Event', {
    id: types.identifier,
    groupName: types.string, // <string> optional group name, this is mostly for admins to have a nice common label for several events that they can understand and group events under i.e. Morning nap.
    typeId: types.string, // <string> ID of event type
    name: types.string, // <string> name of the instance, if it alters from the “parent event”
    conditionalText: types.string, // <string> Some events are done only under a certain condition. For example, there is an earlier event for night-time sleeping if baby didn’t have an afternoon  nap. This is used for now to show in UI of users to act upon the condition themselves.
    ageWeeksMin: types.number, // <int> from which week age this instance applies to (including)
    ageWeeksMax: types.number, // <int> to which week age this instance applies to (including)
    description: types.string, // <string> full description of the event, this will be shortened to a short description in the UI and on user action display full description.
    adjustedStartFrom: '',
    startFrom: types.string, // <string> HH:MM time of day when event starts
    startTo: types.string, // <string> HH:MM time of day when event should have been started (defines range of time when event can be started in)
    duration: types.number, // <int> How long should the event last in minutes, if 0, it means till next event
    stateDuration: 0, // this is added per-instance in the backend
    stateDurationText: '', // this is added per-instance in the backend
    customStateDurationText: '', // this is added per-instance in the backend
    optional: false, // <boolean> Whether this event is optional (not necessary to do)
    troubleshootingTips: types.array(TroubleshootingRef),
    notifications: types.array(EventNotification),
    timePeriodLabel: '',
    eventLogic: types.optional(EventLogic, { conditions: [], disableExclusionWindow: false }),
    hidden: false,
  })
  .volatile((self) => ({
    tipsContent: '',
    isNew: false,
    saving: false,
    saved: false,
  }))
  .views((self) => ({
    get descriptiveLabel() {
      return `${self.name} @${self.startFrom} [${self.ageWeeksMin}w-${self.ageWeeksMax}w]`;
    },
    getStateDurationText(week) {
      if (self.customStateDurationText) {
        return self.customStateDurationText;
      }
      const weekEvents = ssevents.getWeekEvents(0, week, self.manager.events, [], []);
      const wevent = weekEvents.find((we) => we.startFrom === self.startFrom && we.name === self.name);
      return wevent.stateDurationText;
    },
    get hasChanged() {
      const id = self.id;
      return self.manager.hasChanged(id);
    },
    isEventInWeek(week) {
      const e = self;
      return (
        (e.ageWeeksMin <= week.number && e.ageWeeksMax >= week.number) ||
        (e.ageWeeksMax >= week.number && e.ageWeeksMin <= week.number)
      );
    },
    isEventInWeekRange(weekStart, weekEnd) {
      const ageMin = self.ageWeeksMin;
      const ageMax = self.ageWeeksMax;
      return (
        (ageMin >= weekStart && ageMin <= weekEnd) ||
        (ageMax >= weekStart && ageMax <= weekEnd) ||
        (ageMin <= weekStart && ageMax >= weekStart)
      );
    },
    get isLastWakeEventBeforeAnotherSleepEvent() {
      if (self.typeId !== 'wake') return false;

      let eventsOnSameWeek = getParent(self, 1).filter((event) => event.isEventInWeek({ number: self.ageWeeksMin }));
      eventsOnSameWeek = eventsOnSameWeek.filter((event) => event.typeId === 'sleep' || event.typeId === 'wake');
      eventsOnSameWeek = eventsOnSameWeek.sort((a, b) => {
        if (a.startFrom < b.startFrom) {
          return -1;
        }
        if (a.startFrom > b.startFrom) {
          return 1;
        }
        return 0;
      });

      let current = 0;
      let next = current + 1;
      next = next > eventsOnSameWeek.length - 1 ? 0 : next;

      while (current < eventsOnSameWeek.length) {
        const currentEvent = eventsOnSameWeek[current];
        const nextEvent = eventsOnSameWeek[next];
        current++;
        next = current + 1;
        next = next > eventsOnSameWeek.length - 1 ? 0 : next;

        if (currentEvent.id === self.id && currentEvent.typeId === 'wake' && nextEvent.typeId === 'sleep') {
          return true;
        }
      }

      return false;
    },
    get eventsOnSameRange() {
      const events = getParent(self, 1).filter((event) => event.id !== self.id);
      const ageFilteredEvents = events.filter((event) => event.isEventInWeekRange(self.ageWeeksMin, self.ageWeeksMax));

      return ageFilteredEvents;
    },
    get drafts() {
      return self.manager.drafts;
    },
    get manager() {
      return getParentOfType(self, EventManager);
    },
    get summary() {
      return `${self.name} @${self.startFrom} [${self.ageWeeksMin}-${self.ageWeeksMax} weeks]`;
    },
  }))
  .actions((self) => ({
    getDraft() {
      const drafts = getParent(self, 2).drafts;
      const existing = drafts.getUnpublishedByItemID(self.id);
      if (existing) {
        return existing;
      } else {
        // create the draft
        return drafts.createForItem('event', `${self.descriptiveLabel}`, getSnapshot(self), 'edit');
      }
    },
    getTipDraft() {
      if (self.troubleshootingTips.length === 0) {
        self.troubleshootingTips.push({
          id: db.collection('troubleshooting_tips').doc().id,
        });
      }
      const tipID = self.troubleshootingTips[0].id;
      const drafts = getParent(self, 2).drafts;
      const existing = drafts.getUnpublishedByItemID(tipID);
      if (existing) {
        return existing;
      } else {
        // create the draft
        return drafts.createForItem(
          'troubleshooting-tip',
          `Troubleshooting tip on: ${self.descriptiveLabel}`,
          {
            id: tipID,
            content: self.tipsContent || '',
          },
          'edit'
        );
      }
    },
    setIsNew(val) {
      self.isNew = val;
    },
    toJSON() {
      return Object.assign({}, getSnapshot(self));
    },
    set(key, value) {
      self[key] = value;
      self.getDraft().setItemLabel(self.descriptiveLabel);
      self.getDraft().setItemData(key, value);
      self.markChanged();
      self.saved = false;
    },
    save: flow(function* () {
      self.saving = true;
      if (self.troubleshootingTips.length > 0) {
        yield self.getTipDraft().save();
      }
      yield self.getDraft().save();
      yield new Promise((resolve) => setTimeout(resolve, 300));
      self.saving = false;
      self.manager.markUnchanged(self.id);
      self.saved = true;
      self.isNew = false;
    }),
    delete() {
      if (self.isNew) return Promise.resolve();
      return self.getDraft().deleteItem();
    },
    addNotification() {
      self.notifications.push({});
      self.getDraft().setItemData('notifications', getSnapshot(self.notifications));
      self.markChanged();
      self.saved = false;
    },
    removeNotification(index) {
      let n = self.notifications.slice(0);
      n.splice(index, 1);
      self.notifications = n;
      self.getDraft().setItemData('notifications', getSnapshot(self.notifications));
      self.markChanged();
      self.saved = false;
    },
    setNotification(index, data) {
      self.notifications[index] = data;
      self.getDraft().setItemData('notifications', getSnapshot(self.notifications));
      self.markChanged();
      self.saved = false;
    },
    loadTroubleshootingTips: flow(function* () {
      if (!self.troubleshootingTips || self.troubleshootingTips.length < 1) return;

      let id = self.troubleshootingTips[0].id;
      const snap = yield db.collection('troubleshooting_tips').doc(id).get();
      const tip = self.drafts.replaceData('troubleshooting-tip', [Object.assign({}, snap.data(), { id: snap.id })]);
      self.tipsContent = tip[0].content || '';
    }),
    addTroubleshootingTip() {
      const tip = self.getTipDraft();
      tip.setOperation('create');
      tip.setItemData('content', '');
      tip.setItemData('created', new Date());
      tip.setItemData('updated', new Date());
      self.tipsContent = '';
      self.markChanged();
      self.saved = false;
    },
    setTipsContent(content) {
      self.tipsContent = content;
      self.getTipDraft().setItemData('content', content);
      self.markChanged();
      self.saved = false;
    },
    markChanged() {
      self.manager.markChanged(self.id);
    },
  }));
