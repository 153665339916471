import { types, getRoot, flow } from 'mobx-state-tree';
import PostManager from './PostManager.js';
import { autorun, reaction } from 'mobx';

export default types
  .model('PostCollection', {
    villageID: 'all',
    active: 'new',
    all: types.optional(PostManager, {
      sort: 'desc',
      filters: {
        modStatus: '',
      },
    }),
    new: types.optional(PostManager, {
      filters: {
        modStatus: 'new',
      },
    }),
    inProgress: types.optional(PostManager, {
      filters: {
        modStatus: 'inProgress',
      },
    }),
    newReply: types.optional(PostManager, {
      filters: {
        modStatus: 'newReply',
      },
    }),
    closed: types.optional(PostManager, {
      sort: 'desc',
      filters: {
        modStatus: 'closed',
      },
    }),
    search: types.optional(PostManager, {
      sort: 'desc',
      filters: {
        modStatus: '',
      },
    }),
  })
  .volatile((self) => ({
    history: null,
    unsubscribes: [],
  }))
  .views((self) => ({
    get statusManagers() {
      return ['all', 'new', 'inProgress', 'newReply', 'closed'].map((key) => self[key]);
    },
    get managers() {
      return ['all', 'new', 'inProgress', 'newReply', 'closed', 'search'].map((key) => self[key]);
    },
    get current() {
      return self[self.active];
    },
    makeURLPath() {
      const postID = self.current.openedID;
      let path = `/village/${self.villageID || 'all'}/${self.active}`;
      if (postID) {
        path += '/' + postID;
      }
      let hash = [];
      const filters = self.current.filters;
      if (!postID) {
        // dont add filters to URL when viewing a post
        for (let filter of ['author_uid', 'created', 'modAssignedTo']) {
          if (filters[filter]) {
            hash.push(`${filter}:${filters[filter]}`);
          }
        }
      }
      hash = hash.join('.');
      return path + (hash ? '#' + hash : '');
    },
  }))
  .actions((self) => {
    return {
      pushRoute() {
        const path = self.makeURLPath();
        self.history && self.history.push(path);
      },
      init: flow(function* (props, history) {
        self.history = history;
        const { villageID, modStatus, postID, filters } = props;
        self.villageID = villageID || 'all';
        self.active = modStatus || 'new';
        const thisUser = getRoot(self).auth.user;
        self.inProgress.filters.set('modAssignedTo', thisUser.id);
        self.newReply.filters.set('modAssignedTo', thisUser.id);
        self.closed.filters.set('modAssignedTo', thisUser.id);

        if (filters) {
          let tmp = filters.split('#').pop().split('.');
          for (let piece of tmp) {
            let filter = piece.split(':').shift();
            let value = piece.split(':').pop();
            self.current.setFilter(filter, value, true);
          }
        }

        for (let manager of self.statusManagers) {
          if (villageID && villageID !== 'all') {
            manager.filters.set('villageId', villageID);
          }
          manager.load();
        }

        if (modStatus === 'search') {
          self.search.load();
        }

        const villages = getRoot(self).villages;
        reaction(() => self.current.openedID, self.pushRoute);
        reaction(() => self.active, self.pushRoute);
        reaction(() => self.villageID, self.pushRoute);
        reaction(() => self.current.filters.toJSON(), self.pushRoute);
        self.unsubscribes = ['new', 'closed'].map((status) => {
          let lastCount = -1;
          return autorun(() => {
            let currentCount = villages.getCombinedStatusCount(status);
            // console.log('trigger!', lastCount, currentCount, villages.loaded, villages.metrics.loaded);
            if (!villages.loaded || !villages.metrics.loaded) {
              return;
            }
            if (lastCount === -1) {
              lastCount = currentCount;
              return;
            }
            if (lastCount !== currentCount) {
              lastCount = currentCount;
              setTimeout(async () => {
                console.log('Refresh!');
                await self[status].refresh();
                if (postID && window.location.pathname.includes(postID)) {
                  await self.current.loadOne(postID);
                  self.current.open(postID);
                }
              }, 2500);
            }
          });
        });

        if (postID) {
          yield self.current.loadOne(postID);
          self.current.open(postID);
        }

        // when: villages.getCombinedStatusCount({new|inProgress|newReply|closed}) changes:
        // wait ~1second and "refresh" the query for that status and get the new total count + update the posts if needed
      }),
      unsubscribeAll() {
        for (let unsub of self.unsubscribes) {
          unsub();
        }
        self.unsubscribes = [];
      },
      loadMore() {
        self.current.loadMore();
      },
      setActive(newActiveStatus, noload) {
        self.active = newActiveStatus;
        if (newActiveStatus === 'all' && !noload) {
          // refresh
          self.current.load();
        }
      },
      closeSearch() {
        self.search.unload();
        self.search.setSearch('', true);
        self.setActive('all');
      },
      setSearch(keywords) {
        self.current.setSearch('', true);
        self.setActive('search');
        self.current.setSearch(keywords);
      },
      allFromUser(uid) {
        self.setActive('all', true);
        self.setVillage('', true);
        self.current.setSort('desc', true);
        self.current.loadAllFromUser(uid);
      },
      setVillage(newVillage, noload) {
        self.villageID = newVillage;
        for (let manager of self.managers) {
          manager.setFilter('villageId', newVillage.replace('all', ''), noload);
        }
      },
    };
  });
