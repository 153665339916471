import { types } from 'mobx-state-tree';

import AuthModel from './Auth';
import ConfigModel from './Config';
import ContentManagerModel from './ContentManager';
import ContentDraftManagerModel from './ContentDraftManager';
import AdminActivityLogManagerModel from './AdminActivityLogManager';
import ProgramManagerModel from './ProgramManager';
import ProgramArticleManagerModel from './ProgramArticleManager';
import IconCollection from './IconCollection';
import EventTypeManager from './EventTypeManager';
import EventManager from './EventManager';
import UserManager from './UserManager';
import ModManager from './ModManager';
import VillageManagerModel from './VillageManagerModel';
import PostCollection from './PostCollection';
import ScheduleMilestoneManager from './ScheduleMilestoneManager';
import SalesMetrics from './SalesMetricsManager';
import UserMetrics from './UserMetricsManager';
import CommitManager from './CommitManager';
import UI from './UI';

const RootModel = types.model({
  auth: AuthModel,
  config: types.optional(ConfigModel, {}),
  drafts: types.optional(ContentDraftManagerModel, {}),
  content: types.optional(ContentManagerModel, {}),
  adminActivityLog: types.optional(AdminActivityLogManagerModel, {}),
  villages: types.optional(VillageManagerModel, {}),
  programs: types.optional(ProgramManagerModel, {}),
  programArticles: types.optional(ProgramArticleManagerModel, {}),
  icons: types.optional(IconCollection, {}),
  eventTypes: types.optional(EventTypeManager, {}),
  events: types.optional(EventManager, {}),
  scheduleMilestones: types.optional(ScheduleMilestoneManager, {}),
  users: types.optional(UserManager, {}),
  salesMetrics: types.optional(SalesMetrics, {}),
  userMetrics: types.optional(UserMetrics, {}),
  mods: types.optional(ModManager, {}),
  posts: types.optional(PostCollection, {}),
  commits: types.optional(CommitManager, {}),
  ui: types.optional(UI, {}),
});

const root = RootModel.create({
  auth: {
    user: null,
  },
});

root.auth.init();

export const rootStore = root;
