import { types } from 'mobx-state-tree';
import { isSameDay, isAfter, isBefore } from 'date-fns';

export const PERIODS = {
  day: 'day',
  week: 'week',
  month: 'month',
  quarter: 'quarter',
  year: 'year',
};

export default types
  .model('MetricSeriesCollection', {
    data: types.frozen(),
  })
  .views((self) => ({
    get(metric, objectID, dateRange) {
      if (!self.data) {
        return [];
      }
      const dataSeries = self.data.find((d) => d.metric === metric && (objectID ? objectID === d.objectID : true));
      if (!dataSeries) return [];

      const series = (dataSeries.series || []).filter((item) => {
        if (!dateRange) return true;

        const date = new Date(item.x);
        const isAfterDate = isSameDay(date, dateRange.start) || isAfter(date, dateRange.start);
        const isBeforeDate = isSameDay(date, dateRange.end) || isBefore(date, dateRange.end);

        if (dateRange.start && !dateRange.end) {
          return isAfterDate;
        }
        if (dateRange.end && !dateRange.start) {
          return isBeforeDate;
        }

        return isAfterDate && isBeforeDate;
      });

      return series;
    },
    /**
     * @deprecated use __getCombinedMetric instead
     */
    getCombinedMetric(period, name, mode, days) {
      days = days || 30;
      let start = new Date();
      start.setDate(start.getDate() - days);
      start = start.toISOString().split('T').shift();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      endDate = endDate.toISOString().split('T').shift();
      let series = self.data.find((m) => m.metric === name);
      if (series) {
        series = series.series;
      } else {
        console.error('No series for: ', period, name, mode, days);
        return 0;
      }
      const items = series.filter((s) => s.x >= start && s.x <= endDate);
      if (items.length === 0) {
        return 0;
      }
      if (mode === 'sum') {
        return items.reduce((m, metric) => {
          return m + metric.y;
        }, 0);
      } else if (mode === 'avg') {
        let total = 0;
        let sum = items.reduce((m, metric) => {
          total += metric.count || 1;
          return m + metric.y;
        }, 0);
        return sum / total;
      }
    },
    /**
     * @param {string} name name of the metric
     * @param {Object} options options
     * @param {string} options.mode 'avg' or 'sum'
     * @param {string} options.period period of the combined metric, one of 'day' | 'week' | 'month' | 'quarter' | 'year'
     * @param {Object} options.dateRange range of metric
     * @param {Date} options.dateRange.start start of the date range
     * @param {Date} options.dateRange.end end of the date range
     * @returns {number} combined metric
     */
    __getCombinedMetric(name, { mode = 'sum', period = 'day', dateRange: { start, end } }) {
      if (!self.data) return [];

      const dataSeries = self.data.find((d) => d.metric === name);
      if (!dataSeries) return [];

      let series = (dataSeries.series || []).filter((item) => {
        if (!start && !end) return true;

        const date = new Date(item.x);
        const isAfterDate = isSameDay(date, start) || isAfter(date, start);
        const isBeforeDate = isSameDay(date, end) || isBefore(date, end);

        if (start && !end) {
          return isAfterDate;
        }
        if (end && !start) {
          return isBeforeDate;
        }

        return isAfterDate && isBeforeDate;
      });

      const total = series.reduce((total, data) => total + data.y, 0);
      if (mode === 'avg') {
        switch (period) {
          case 'week':
            series = series
              .reduceRight((mergedData, currentData, index) => {
                if (mergedData.length === 0) {
                  mergedData.push(currentData);
                  return mergedData;
                }

                if (index % 7 === 0) {
                  mergedData.push(currentData);
                } else {
                  const last = mergedData.pop();
                  mergedData.push({
                    x: last.x,
                    y: last.y + currentData.y,
                  });
                }

                return mergedData;
              }, [])
              .reverse();
            break;
          case 'month':
            series = series
              .reduceRight((mergedData, currentData) => {
                if (mergedData.length === 0) {
                  mergedData.push(currentData);
                  return mergedData;
                }

                const last = mergedData.pop();
                const lastItemDate = new Date(last.x);
                const currentItemDate = new Date(currentData.x);

                lastItemDate.setMonth(lastItemDate.getMonth() - 1);
                if (isSameDay(currentItemDate, lastItemDate)) {
                  mergedData.push(last);
                  mergedData.push(currentData);
                } else {
                  mergedData.push({
                    x: last.x,
                    y: last.y + currentData.y,
                  });
                }

                return mergedData;
              }, [])
              .reverse();
            break;
          case 'quarter':
            series = series
              .reduceRight((mergedData, currentData) => {
                if (mergedData.length === 0) {
                  mergedData.push(currentData);
                  return mergedData;
                }

                const last = mergedData.pop();
                const lastItemDate = new Date(last.x);
                const currentItemDate = new Date(currentData.x);

                lastItemDate.setMonth(lastItemDate.getMonth() - 3);
                if (isSameDay(currentItemDate, lastItemDate)) {
                  mergedData.push(last);
                  mergedData.push(currentData);
                } else {
                  mergedData.push({
                    x: last.x,
                    y: last.y + currentData.y,
                  });
                }

                return mergedData;
              }, [])
              .reverse();
            break;
          case 'year':
            series = series
              .reduceRight((mergedData, currentData) => {
                if (mergedData.length === 0) {
                  mergedData.push(currentData);
                  return mergedData;
                }

                const last = mergedData.pop();
                const lastItemDate = new Date(last.x);
                const currentItemDate = new Date(currentData.x);

                lastItemDate.setFullYear(lastItemDate.getFullYear() - 1);
                if (isSameDay(currentItemDate, lastItemDate)) {
                  mergedData.push(last);
                  mergedData.push(currentData);
                } else {
                  mergedData.push({
                    x: last.x,
                    y: last.y + currentData.y,
                  });
                }

                return mergedData;
              }, [])
              .reverse();
            break;
          default:
        }
        return total / series.length;
      } else {
        return total;
      }
    },
  }))
  .actions((self) => ({
    setData(newValue) {
      self.data = newValue;
    },
  }));
