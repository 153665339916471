import { types, flow, getParent, getRoot } from 'mobx-state-tree';
import firebase, { FieldValue } from './../helpers/firebase';
import PostComment from './PostComment';
const db = firebase.firestore();

export default types
  .model('PostCommentManager', {
    items: types.array(PostComment),
    sort: 'desc',
  })
  .views((self) => ({
    get post() {
      return getParent(self, 1);
    },
    get sorted() {
      return self.items;
    },
    get last() {
      let sorted = self.sorted;
      return sorted[sorted.length - 1];
    },
  }))
  .volatile((self) => ({
    listeners: [],
    loading: false,
    loaded: false,
  }))
  .actions((self) => ({
    send: flow(function* (text) {
      try {
        const thisUser = getRoot(self).auth.user;
        const post = self.post;

        if (post.modStatus === 'new' || post.modStatus === 'newReply') {
          yield post.updateStatus('closed');
        }
        if (!post.isAssignedToMe) {
          yield post.toggleAssignTo(post.thisUser.id);
        }

        yield db.collection('post_comments').doc().set({
          origin: 'dashboard',
          postId: self.post.id, // <string> id of the post this comment is posted under
          text: text, // <string> comment text / content
          author_uid: thisUser.id, // <string> id of the author user
          author_avatar: thisUser.avatar, // <string> avatar image URL of the author user
          author_displayName: thisUser.fullName,
          author_signature: thisUser.modSignature,
          likesCount: 0,
          created: FieldValue.serverTimestamp(), // <timestamp> when the post comment was created
        });
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    mergePostComment(postComment) {
      let existing = self.items.find((p) => p.id === postComment.id);
      if (existing) {
        existing.update(postComment);
      } else {
        self.items.push(postComment);
      }
    },
    setItems(items) {
      self.items = items;
    },
    load: function () {
      self.loading = true;

      self.listeners.push(
        db
          .collection('post_comments')
          .where('postId', '==', self.post.id)
          .orderBy('created', 'asc')
          .onSnapshot((querySnapshot) => {
            const comments = querySnapshot.docs.map((doc) => {
              let comment = Object.assign({}, doc.data(), { id: doc.id });
              return comment;
            });
            self.setItems(comments);
            self.setLoaded();
          })
      );
    },
    setLoaded() {
      self.loading = false;
      self.loaded = true;
    },
    unload() {
      for (let unsubscribe of self.listeners) {
        unsubscribe();
      }
      self.loaded = false;
      self.loading = false;
      self.listeners = [];
      // self.items = []; - this causes a crash for some reason
    },
  }));
