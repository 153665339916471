import { types, flow, getParent } from 'mobx-state-tree';
import firebase from '../helpers/firebase';
import { snapToArray } from '../helpers/firestore';
import Timestamp from './Timestamp';

const db = firebase.firestore();

const Log = types
  .model('AdminActivityLogItem', {
    id: types.string,
    uid: types.string,
    userName: types.string,
    message: types.string,
    messageLinks: types.frozen(),
    time: types.maybe(Timestamp),
  })
  .views((self) => ({
    renderMessage() {
      let msg = self.message;
      let data = JSON.parse(self.messageLinks);

      const regex = `(${data.map((link) => link[0].replace(/[()-]/gi, '.')).join('|')})`;
      msg = msg.replace(new RegExp(regex, 'ig'), (match) => {
        let url = data.find((link) => link[0] === match);
        if (!url) {
          return match;
        } else {
          url = url[1];
        }
        return '<a href="' + url + '">' + match + '</a>';
      });
      return msg;
    },
    renderTime() {
      return new Date(self.time.milliseconds).toLocaleString();
    },
  }));

export default types
  .model('AdminActivityLogManager', {
    logs: types.array(Log),
  })
  .views((self) => ({
    get empty() {
      return self.logs.length === 0;
    },
    get loading() {
      return !self.loaded;
    },
  }))
  .volatile((self) => ({
    loaded: false,
  }))
  .actions((self) => ({
    load: flow(function* (uid) {
      self.loaded = false;
      let query = db.collection('admin_activity_log');
      if (uid) {
        query = query.where('uid', '==', uid);
      }
      query = query.orderBy('time', 'desc').limit(1000);
      const contentData = yield query.get();
      self.logs = snapToArray(contentData);
      self.loaded = true;
    }),
    add: flow(function* (message, links) {
      links = links || [];
      const user = getParent(self).auth.user;
      yield db
        .collection('admin_activity_log')
        .doc()
        .set({
          uid: user.id,
          userName: user.first_name,
          message: message,
          messageLinks: JSON.stringify(links),
          time: new Date(),
        });
    }),
  }));
