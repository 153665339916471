import env from './../environment';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

export const FieldValue = firebase.firestore.FieldValue;
export function getFunctionURL(name) {
  return `https://us-central1-${env.firebase.projectId}.cloudfunctions.net/httpsUserEndOnlineSession`;
}
export default firebase.initializeApp(env.firebase);
