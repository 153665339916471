import { types } from 'mobx-state-tree';

function getBornTimestamp(now, babyDue, babyBirth) {
  const birthDifference = Math.abs(babyDue - babyBirth);
  let start = 0;

  // if difference between birth and due date is less than 14 days ...
  if (birthDifference < 14 * 24 * 3600) {
    start = babyBirth;
  } else {
    start = babyDue;
  }

  const age = Math.floor((now - start) / (24 * 3600));
  if (age >= 365) {
    // at least one year old, recalculate from the birth date
    return babyBirth;
  } else {
    return start;
  }
}

function babyBornLateEarlyDays(babyDue, babyBirth) {
  const birthDifference = babyBirth - babyDue;
  return Math.round(birthDifference / (24 * 3600));
}

function ageOfBabyText(now, babyDue, babyBirth) {
  const days = ageOfBabyDays(now, babyDue, babyBirth);
  const months = (function () {
    const start = getBornTimestamp(now, babyDue, babyBirth);
    const startDate = new Date(start * 1000);
    const nowDate = new Date(now * 1000);
    let months = 0;
    do {
      startDate.setMonth(startDate.getMonth() + 1);
      months++;
    } while (startDate.getTime() <= nowDate.getTime());
    months = months - 1;
    if (months >= 12) {
      months = months % 12;
    }
    return months;
  })();
  const years = (function () {
    const start = getBornTimestamp(now, babyDue, babyBirth);
    const startDate = new Date(start * 1000);
    const nowDate = new Date(now * 1000);
    let months = 0;
    do {
      startDate.setMonth(startDate.getMonth() + 1);
      months++;
    } while (startDate.getTime() <= nowDate.getTime());
    months = months - 1;
    return Math.floor(months / 12);
  })();
  const weeks = Math.floor(days / 7);
  const weeksText = `${weeks} week${weeks === 1 ? '' : 's'}`;
  if (days < 0) {
    return '0 weeks';
  } else if (days === 0) {
    return 'Born today!';
  } else if (years < 1 && months < 3) {
    // # weeks
    return weeksText;
  } else if (years < 1) {
    // # months (# weeks)
    return `${months} month${months === 1 ? '' : 's'} (${weeksText})`;
  } else {
    // # years and # months (# weeks)
    let str = `${years} year${years === 1 ? '' : 's'}`;
    if (months > 0) {
      str += ` and ${months} month${months === 1 ? '' : 's'}`;
    }
    return `${str} (${weeksText})`;
  }
}

function ageOfBabyDays(now, babyDue, babyBirth) {
  const start = getBornTimestamp(now, babyDue, babyBirth);
  const age = Math.floor((now - start) / (24 * 3600));
  return age;
}

/**
 * Return age of the baby (in weeks)
 * @param {int} now Time now, as Timestamp since UNIX epoch in seconds
 * @param {int} babyDue Baby due date, as Timestamp since UNIX epoch in seconds
 * @param {int} babyBirth Baby birth date, as Timestamp since UNIX epoch in seconds
 * @returns {int} Babe age in weeks, i.e. 33, NOTICE: It can be negative age as well, when now is less than due date and we calculate from due date
 */
function ageOfBaby(now, babyDue, babyBirth) {
  const birthDifference = Math.abs(babyDue - babyBirth);
  let start = 0;

  // if difference between birth and due date is less than 14 days ...
  if (birthDifference < 14 * 24 * 3600) {
    start = babyBirth;
  } else {
    start = babyDue;
  }

  const age = Math.floor((now - start) / (7 * 24 * 3600));
  if (age >= 52) {
    // at least one year old, recalculate from the birth date
    return Math.floor((now - babyBirth) / (7 * 24 * 3600));
  } else {
    return age;
  }
}
function isCorrectedAge(now, babyDue, babyBirth) {
  const birthDifference = Math.abs(babyDue - babyBirth);
  let start = 0;

  let isCorrected = false;

  // if difference between birth and due date is less than 14 days ...
  if (birthDifference < 14 * 24 * 3600) {
    start = babyBirth;
    isCorrected = false;
  } else {
    start = babyDue;
    isCorrected = true;
  }

  const age = Math.floor((now - start) / (7 * 24 * 3600));
  if (age >= 52) {
    // at least one year old, recalculate from the birth date
    isCorrected = false;
  }

  return isCorrected;
}

export default types
  .model('LittleOne', {
    id: types.maybe(types.string), // randomly generated ID
    IsPregnant: types.maybe(types.boolean),
    Name: '', // Name of child
    Gender: '', // Gender
    // DateOfBirth: types.maybeNull(types.optional(types.string, '')), // Date of birth
    DateOfBirth: types.union(types.maybe(types.string), types.maybe(types.number)),
    DueDate: types.maybe(types.string), // Due date
    EventTimeOffset: 0,
  })
  .views((self) => ({
    get ageText() {
      const now = new Date().getTime() / 1000;
      const born = new Date((self.DateOfBirth || self.DueDate) + 'T00:00:00.000Z').getTime() / 1000;
      const due = new Date(self.DueDate + 'T00:00:00.000Z').getTime() / 1000;
      return ageOfBabyText(now, due, born);
    },
    get weeksAge() {
      const now = new Date().getTime() / 1000;
      const born = new Date((self.DateOfBirth || self.DueDate) + 'T00:00:00.000Z').getTime() / 1000;
      const due = new Date(self.DueDate + 'T00:00:00.000Z').getTime() / 1000;
      return ageOfBaby(now, due, born);
    },
    get isCorrectedAge() {
      const now = new Date().getTime() / 1000;
      const born = new Date((self.DateOfBirth || self.DueDate) + 'T00:00:00.000Z').getTime() / 1000;
      const due = new Date(self.DueDate + 'T00:00:00.000Z').getTime() / 1000;
      return isCorrectedAge(now, due, born);
    },
    get bornLateEarly() {
      const born = new Date((self.DateOfBirth || self.DueDate) + 'T00:00:00.000Z').getTime() / 1000;
      const due = new Date(self.DueDate + 'T00:00:00.000Z').getTime() / 1000;
      return babyBornLateEarlyDays(due, born);
    },
    get bornLateEarlyText() {
      const diff = self.bornLateEarly;
      const absDiff = Math.abs(diff);
      const days = absDiff > 1 ? 'days' : 'day';
      if (diff === 0) {
        return '';
      } else {
        if (diff > 0) {
          return `${absDiff} ${days} late`;
        } else {
          return `${absDiff} ${days} early`;
        }
      }
    },
  }));
