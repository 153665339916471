import { types } from 'mobx-state-tree';

export default types
  .model('Metric', {
    id: types.string,
    objectType: types.string,
    objectID: types.string,
    metric: types.string,
    time: types.string,
    timePeriod: types.string,
    value: 0,
    values: types.array(types.number),
    count: 0,
  })
  .actions((self) => ({
    update(value, count) {
      self.value = value;
      self.count = count;
    },
  }));
