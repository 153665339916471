import { types, flow, getSnapshot } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import Timestamp from './Timestamp';
const db = firebase.firestore();

const PostMemoChange = types.model('PostMemoChange', {
  author_uid: types.string,
  author_displayName: types.string,
  created: Timestamp,
});

const PostMemo = types
  .model('PostMemo', {
    postId: types.string,
    text: '',
    changes: types.array(PostMemoChange),
  })
  .volatile((self) => ({
    textChanged: false,
    saving: false,
  }))
  .actions((self) => ({
    setText(newText) {
      self.text = newText;
      self.textChanged = true;
    },
    saveChanges: flow(function* (author) {
      self.saving = true;
      // add to changes
      self.changes.push({
        author_uid: author.id,
        author_displayName: `${author.first_name} ${author.last_name}`,
        created: new Date(),
      });
      yield db.collection('post_notes').doc(self.postId).set(getSnapshot(self));
      self.saving = false;
    }),
  }));

export default PostMemo;
