import { types, getParent, flow, getRoot, isAlive } from 'mobx-state-tree';
import firebase, { FieldValue } from './../helpers/firebase.js';
import PostCommentManager from './PostCommentManager.js';
import PostMemo from './PostMemo.js';
import Timestamp from './Timestamp.js';
import { LogEntry } from './ModActivityLog';

const db = firebase.firestore();

const schema = {
  id: types.string,
  villageId: types.string, // <string> id of the village this post belongs to
  subject: types.string, // <string> subject line of the post
  description: types.string, // <string> description of the post
  author_uid: types.string, // <string> id of the author user
  author_avatar: types.string, // <string> avatar image URL of the author user
  author_displayName: types.string, // <string> author users name
  modStatus: 'new', // <string|undefined> status that places the post in moderators UI in groups: "new","inProgress","newReply","closed" . This value may be undefined, in which case, its status is "new"
  modAssignedTo: types.array(types.string), // <array[string]> array of moderator user ids to whom this post is assigned to (in the moderators UI)
  modAssigned: false,
  origin: '', // this can be set to "ambit", which mean that the post was posted from ambit
  likesCount: types.number, // <int> Number of likes
  commentCount: types.number, // <int> Number of comments
  liked_by: types.array(types.string), // <array[string]> array of user ids that liked this post
  followers: types.array(types.string), // <array[string]> array of user ids that follow this post
  commenters: types.array(types.string), // <array[string]> array of user ids that commented under a post
  frozen: false, // <boolean> if true, post is frozen - cannot be interacted with by regular users
  topics: types.array(types.string), // <array[string> keeps an array of topics this post belongs to
  created: types.maybe(types.maybeNull(Timestamp)), // <timestamp> when the post was created
  modStatusUpdated: types.maybe(types.maybeNull(Timestamp)),
  comments: types.optional(PostCommentManager, {}),
  logs: types.array(LogEntry),
};

const keys = Object.keys(schema);

export default types
  .model('Post', schema)
  .volatile((self) => ({
    loadingAmbitURL: false,
    ambitURL: '',
    likedNow: null,
    loadingMemo: false,
    memo: null,
    unsubscribe: null,
    startedReply: false,
    movingVillage: false,
  }))
  .views((self) => ({
    get isSuccessStory() {
      return self.topics.includes('Success Story');
    },
    get manager() {
      return getParent(self, 2);
    },
    get ref() {
      return db.collection('posts').doc(self.id);
    },
    get hasLiked() {
      const thisUser = getRoot(self).auth.user;
      if (typeof self.likedNow === 'boolean') {
        return self.likedNow;
      } else {
        return self.liked_by.includes(thisUser.id);
      }
    },
    get hasCommented() {
      const thisUser = getRoot(self).auth.user;
      return self.commenters.includes(thisUser.id);
    },
    get thisUser() {
      return getRoot(self).auth.user;
    },
    get isAssignedToMe() {
      const thisUser = getRoot(self).auth.user;
      return self.modAssignedTo.includes(thisUser.id);
    },
  }))
  .actions((self) => ({
    likeToggle() {
      const thisUser = getRoot(self).auth.user;
      if (self.hasLiked) {
        db.collection('post_likes')
          .doc(thisUser.id + self.id)
          .delete();
      } else {
        db.collection('post_likes')
          .doc(thisUser.id + self.id)
          .set({
            uid: thisUser.id,
            postId: self.id,
            created: FieldValue.serverTimestamp(), // <timestamp> when the post was liked
          });
      }
      self.likedNow = !(self.likedNow === 'boolean' ? self.likedNow : self.liked_by.includes(thisUser.id));
    },
    loadAmbitURL: flow(function* () {
      self.loadingAmbitURL = true;
      const meta = yield db.collection('post_meta').doc(self.id).get();
      self.ambitURL = meta.get('ambit.conversationURI');
      self.loadingAmbitURL = false;
    }),
    loadMemo: flow(function* () {
      self.loadingMemo = true;
      const result = yield db.collection('post_notes').doc(self.id).get();
      const data = result.data();
      if (!data) {
        // no memo yet
        self.memo = PostMemo.create({
          postId: self.id,
        });
      } else {
        self.memo = PostMemo.create(data);
      }
      self.loadingMemo = false;
    }),
    loadLogs: flow(function* () {
      const result = yield db.collection('mod_activity_log').where('meta.postId', '==', self.id).get();
      const arr = result.docs
        .slice(0)
        .map((snap) => Object.assign({}, snap.data(), { id: snap.id }))
        .filter((record) => !!record.time);
      arr.sort((a, b) => (a.time && b.time ? a.time.toMillis() - b.time.toMillis() : -1));
      self.logs = arr;
    }),
    load() {
      if (self.unsubscribe) return;
      self.unsubscribe = self.ref.onSnapshot(
        (snap) => {
          if (!snap.exists) {
            // if opened by current moderator
            if (self.manager.opened && self.manager.opened.id === self.id) {
              // this post is opened by mod
              self.manager.close();
              getRoot(self).ui.alerts.set({
                color: 'danger',
                text: `The post you were viewing has been deleted. Post author UID: ${self.author_uid}`,
              });
            }
            // deleted
            self.unload();
            self.manager.remove(self.id);
            return;
          }
          const data = Object.assign({}, snap.data());
          if (typeof data.frozen !== 'boolean') {
            data.frozen = data.frozen === 'true';
          }
          if (isAlive(self)) {
            self.update(data);
          } else {
            self.unsubscribe();
          }
        },
        (err) => {
          if (self.manager.opened && self.manager.opened.id === self.id) {
            // this post is opened by mod
            self.manager.close();
            getRoot(self).ui.alerts.set({
              color: 'danger',
              text: `Failed to load the village post. Please refresh and try again`,
            });
          }
        }
      );
    },
    unload() {
      if (self.unsubscribe) {
        self.unsubscribe();
        self.unsubscribe = null;
      }
      self.unloadComments();
    },
    toggleAssignTo: flow(function* (uid) {
      if (self.modAssignedTo.includes(uid)) {
        self.modAssignedTo.splice(self.modAssignedTo.indexOf(uid), 1);
        yield self.ref.update({
          modAssignedTo: FieldValue.arrayRemove(uid),
        });
        self.thisUser.logModActivity('assigned-updated', {
          postId: self.id,
          removedModAssignedTo: uid,
        });
      } else {
        self.modAssignedTo.push(uid);
        yield self.ref.update({
          modAssignedTo: FieldValue.arrayUnion(uid),
        });
        self.thisUser.logModActivity('assigned-updated', {
          postId: self.id,
          addedModAssignedTo: uid,
        });
      }
    }),
    updateStatus: flow(function* (newStatus) {
      if (self.modStatus === newStatus) return null;
      const oldStatus = self.modStatus;
      self.modStatus = newStatus;
      yield self.ref.update({
        modStatus: newStatus,
        modStatusUpdated: FieldValue.serverTimestamp(),
      });
      self.thisUser.logModActivity('status-updated', {
        postId: self.id,
        oldModStatus: oldStatus,
        newModStatus: newStatus,
      });
    }),
    logStartedReply() {
      if (self.startedReply) return;
      self.startedReply = true;
      self.thisUser.logModActivity('reply-started', {
        postId: self.id,
      });

      // auto assign
      const uid = self.thisUser.id;
      if (!self.modAssignedTo.includes(uid)) {
        self.toggleAssignTo(uid);
      }
    },
    toggleFrozen() {
      self.thisUser.logModActivity('updated-frozen', {
        postId: self.id,
        frozen: !self.frozen,
      });
      if (self.frozen) {
        self.frozen = false;
        self.ref.update({
          frozen: false,
        });
      } else {
        self.frozen = true;
        self.ref.update({
          frozen: true,
        });
      }
    },
    toggleSuccessStory() {
      self.thisUser.logModActivity('updated-success', {
        postId: self.id,
        success: !self.isSuccessStory,
      });
      if (self.isSuccessStory) {
        self.topics.splice(self.topics.indexOf('Success Story'), 1);
        self.ref.update({
          topics: FieldValue.arrayRemove('Success Story'),
        });
      } else {
        self.ref.update({
          topics: FieldValue.arrayUnion('Success Story'),
        });
      }
    },
    update(data) {
      for (let key of Object.keys(data)) {
        if (!keys.includes(key)) continue;
        self[key] = data[key];
      }
    },
    loadComments() {
      self.comments.load();
    },
    unloadComments() {
      self.comments.unload();
    },
    delete() {
      self.thisUser.logModActivity('deleted-post', {
        postId: self.id,
        postSubject: self.subject,
        postDescription: self.description,
        postAuthorUID: self.author_uid,
      });
      self.unload();
      self.ref.delete();
      self.manager.remove(self.id);
    },
    moveVillage: flow(function* (newVillageID) {
      if (self.villageId === newVillageID) throw Error('New village id cannot be the same as old village id');

      try {
        self.movingVillage = true;

        const httpsAdminPostMoveToVillage = firebase.functions().httpsCallable('httpsAdminPostMoveToVillage');
        yield httpsAdminPostMoveToVillage({ postId: self.id, villageId: newVillageID });

        self.villageId = newVillageID;
        self.movingVillage = false;
        return Promise.resolve(newVillageID);
      } catch (error) {
        console.error(error);
        self.movingVillage = false;
        return Promise.reject(error);
      }
    }),
  }));
