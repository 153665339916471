import { types, getRoot } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
import User from './User';
import { when } from 'mobx';
const db = firebase.firestore();

export default types
  .model('ModManager', {
    users: types.array(User),
  })
  .views((self) => ({
    get totalCount() {
      return self.users.length;
    },
    get onlineCount() {
      return self.users.filter((u) => u.modActiveStatus === 'online').length;
    },
    get offlineCount() {
      return self.users.filter((u) => u.modActiveStatus === 'offline').length;
    },
    get absentCount() {
      return self.users.filter((u) => u.modActiveStatus === 'absent').length;
    },
    get sorted() {
      let arr = self.users.slice(0);
      arr.sort((a, b) => {
        if (a.modName > b.modName) {
          return 1;
        } else if (b.modName > a.modName) {
          return -1;
        } else {
          return 0;
        }
      });
      return arr;
    },
    othersViewPost(postID) {
      const thisUser = getRoot(self).auth.user;
      let fiveMinAgo = new Date();
      fiveMinAgo.setMinutes(fiveMinAgo.getMinutes() - 5);
      fiveMinAgo = fiveMinAgo.getTime();
      return self.users.filter((user) => {
        const happenedLessThan5MinAgo =
          user.modActiveStatus !== 'online' &&
          user.modLastActivity &&
          user.modLastActivity.time.milliseconds > fiveMinAgo;
        return (
          user.modLastActivity.objectID === postID &&
          user.modLastActivity.objectType === 'post' &&
          (user.modActiveStatus === 'online' || happenedLessThan5MinAgo) &&
          user.id !== thisUser.id
        );
      });
    },
  }))
  .volatile((self) => ({
    loaded: false,
    loading: false,
  }))
  .actions((self) => ({
    setUsers(snaps) {
      let users = snapToArray(snaps);
      for (let user of users) {
        let existing = self.users.find((u) => u.id === user.id);
        if (existing) {
          existing.updateData(user);
        } else {
          self.users.push(user);
        }
      }
      if (users.length > 1) {
        self.loaded = true;
        self.loading = false;
      }
    },
    setLoaded() {
      self.loaded = true;
      self.loading = false;
    },
    load: function () {
      if (self.loaded || self.loading) return when(() => self.loaded);
      self.loaded = false;
      self.loading = true;
      return new Promise((resolve) => {
        db.collection('users')
          .where('roles.village_moderator', '==', true)
          .onSnapshot((snaps) => {
            self.setUsers(snaps);
            resolve();
          });
      });
    },
  }));
