import { types, flow, getSnapshot } from 'mobx-state-tree';
import Icon from './Icon';
import firebase from '../helpers/firebase';
import { snapToArray } from '../helpers/firestore';

const db = firebase.firestore();

export default types
  .model('IconCollection', {
    icons: types.array(Icon),
  })
  .views((self) => ({
    ofType(type) {
      return self.icons.filter((icon) => icon.type === type);
    },
    get(id) {
      return self.icons.find((icon) => icon.id === id);
    },
    get embedded() {
      const data = getSnapshot(self);
      return Object.assign({}, data);
    },
  }))
  .actions((self) => ({
    load: flow(function* (type) {
      let query = db.collection('icons');
      if (type) {
        query = query.where('type', '==', type);
      }
      const iconData = yield query.get();
      for (let icon of snapToArray(iconData)) {
        if (!self.get(icon.id)) {
          self.icons.push(icon);
        }
      }
    }),
    concat: function (arr) {
      for (let icon of arr) {
        if (!self.get(icon.id)) {
          self.icons.push(icon);
        }
      }
    },
  }));
