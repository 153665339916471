import { pickBy } from 'lodash';

export function snapToArray(snapshot) {
  var returnArr = [];

  snapshot.forEach(function (childSnapshot) {
    var item = childSnapshot.data();
    item.id = childSnapshot.id;

    returnArr.push(item);
  });

  return returnArr;
}

export function cleanUndefinedProps(obj) {
  return pickBy(obj, (v) => typeof v !== 'undefined');
}
