import { types, getSnapshot, getParent, flow } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import Timestamp from './Timestamp';

const db = firebase.firestore();

export default types
  .model('ContentDraft', {
    id: types.identifier,
    commitId: '', // default empty string (unpublished draft), if set, its connected to a commit (published draft)
    operation: types.enumeration(['edit', 'create', 'delete']), // edit|create|delete
    itemLabel: types.string, // descriptive label of the item, i.e. article title, or, an event line (name + time + age range)
    itemType: types.enumeration([
      'program',
      'program-article',
      'event',
      'troubleshooting-tip',
      'schedule-milestone',
      'blog-post',
      'other-content',
    ]),
    itemId: types.string,
    itemData: types.frozen(), // data of the item, if null, it means the item was deleted
    lastChangeTime: types.maybe(Timestamp),
    changedBy: types.array(types.string), // who made changes (admin UIDs)
  })
  .views((self) => ({
    get ref() {
      return db.collection('content_drafts').doc(self.id);
    },
    get operationIntent() {
      if (self.operation === 'delete') {
        return 'danger';
      } else if (self.operation === 'create') {
        return 'primary';
      } else {
        return 'info';
      }
    },
  }))
  .volatile((self) => ({
    unsubscribe: null,
    saved: false,
  }))
  .actions((self) => ({
    listen() {
      self.unsubscribe = self.ref.onSnapshot((snap) => {
        if (snap.get('commitId')) {
          // remove this draft, it has been published
          getParent(self, 2).removeUnpublished(self.id);
        }
      });
    },
    stopListening() {
      self.unsubscribe && self.unsubscribe();
    },
    update(key, value) {
      self[key] = value;
      const upd = {
        lastChangeTime: new Date(),
      };
      upd[key] = value;
      return self.ref.update(upd);
    },
    updateItemData(key, value) {
      if (typeof key === 'object') {
        let d = Object.assign({}, self.itemData || {});
        let keys = Object.keys(key);
        for (let k of keys) {
          d[k] = key[k];
        }
        self.itemData = d;
        return self.save();
      } else {
        let d = Object.assign({}, self.itemData || {});
        d[key] = value;
        self.itemData = d;
        return self.save();
      }
    },
    setSaved(value) {
      self.saved = value;
    },
    setItemLabel(newLabel) {
      self.itemLabel = newLabel;
    },
    setItemData(key, value) {
      if (!self.itemData) {
        self.itemData = {};
      }
      if (typeof key === 'object') {
        let keys = Object.keys(key);
        for (let k of keys) {
          self.setItemData(k, key[k]);
        }
      } else {
        if (key.includes('.')) {
          let d = Object.assign({}, self.itemData);
          let obj;
          let keys = key.split('.');
          let last = keys.pop();
          obj = keys.reduce((m, key) => {
            if (typeof m[key] === 'object') {
              m[key] = Object.assign({}, m[key]);
            }
            return m[key];
          }, d);
          obj[last] = value;
          self.itemData = d;
        } else {
          let d = Object.assign({}, self.itemData);
          d[key] = value;
          self.itemData = d;
        }
      }
    },
    set(key, value) {
      self[key] = value;
    },
    save: flow(function* () {
      self.lastChangeTime = new Date();
      self.saved = true;
      const snapshot = getSnapshot(self);
      console.log('save the draft: ', { snapshot });
      return yield self.ref.set(snapshot);
    }),
    setOperation(operation) {
      self.operation = operation;
    },
    delete: flow(function* () {
      // delete the draft
      yield self.ref.delete();
      getParent(self, 2).removeUnpublished(self.id);
    }),
    deleteItem: flow(function* () {
      if (self.operation === 'create') {
        yield self.delete();
      } else {
        self.itemData = null;
        self.operation = 'delete';
        return yield self.save();
      }
    }),
  }));
