import { types } from 'mobx-state-tree';
import { createdAgo, DateFormat } from './../helpers/time';

const Timestamp = types
  .model('Timestamp', {
    milliseconds: types.number,
  })
  .preProcessSnapshot((snap) => {
    if (snap && typeof snap === 'string') {
      return {
        milliseconds: new Date(snap).getTime(),
      };
    } else if (snap && snap.toMillis) {
      return {
        milliseconds: snap.toMillis(),
      };
    } else if (snap && snap.getTime) {
      return Object.assign(
        {},
        {
          milliseconds: snap.getTime(),
        }
      );
    } else if (snap && snap.seconds) {
      return {
        milliseconds: snap.seconds * 1000,
      };
    } else if (snap && snap._seconds) {
      return {
        milliseconds: snap._seconds * 1000,
      };
    } else if (!snap || Object.keys(snap).length === 0) {
      return {
        milliseconds: new Date().getTime(),
      };
    } else {
      return snap;
    }
  })
  .postProcessSnapshot((snap) => {
    return new Date(snap.milliseconds);
  })
  .views((self) => ({
    get date() {
      return new Date(self.milliseconds);
    },
    get ago() {
      return createdAgo(self.date);
    },
    get fullLocalDate() {
      return self.date.toLocaleString();
    },
  }))
  .actions((self) => ({
    format(options) {
      options = options || {};
      return DateFormat({
        timestamp: self.milliseconds,
        longDate: options.longDate,
        shortDate: options.short,
        withTime: options.withTime,
      });
    },
  }));

export const MaybeTimestamp = types.optional(
  types.union(types.literal(undefined), types.literal(null), Timestamp),
  undefined
);
export default Timestamp;
