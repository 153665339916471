import { types, getParent, flow } from 'mobx-state-tree';
import Timestamp from './Timestamp';
import firebase from 'helpers/firebase';
import { snapToArray } from 'helpers/firestore';
import * as dateFns from 'date-fns';

const db = firebase.firestore();

export const LogEntry = types
  .model('UserActivityLogEntry', {
    id: types.string,
    event: types.string,
    time: Timestamp,
    data: types.frozen(),
  })
  .views((self) => ({
    get description() {
      const { event, data } = self;

      if (event === 'Added a LO') {
        if (!data.Name && !data.DateOfBirth) {
          return `Added Little One`;
        }

        if (!data.DateOfBirth) {
          return `Added Little One ${data.Name}`;
        }

        if (!data.Name) {
          return `Added Little One, born ${dateFns.format(
            dateFns.parse(data.DateOfBirth, 'yyyy-MM-dd', new Date()),
            'dd MMM yyyy'
          )}`;
        }

        return `Added Little One ${data.Name}, born ${dateFns.format(
          dateFns.parse(data.DateOfBirth, 'yyyy-MM-dd', new Date()),
          'dd MMM yyyy'
        )}`;
      }

      if (event === 'Deleted a LO') {
        return `Deleted Little One ${data.Name}, born ${dateFns.format(
          dateFns.parse(data.DateOfBirth, 'yyyy-MM-dd', new Date()),
          'dd MMM yyyy'
        )}`;
      }

      if (event === 'Updated a LO') {
        const messages = Object.keys(data.new).reduce((messages, objectKey) => {
          if (data.old[objectKey] === data.new[objectKey]) return messages;

          if (messages.length === 0) {
            messages.push(`Updated ${objectKey} property of LO ${data.old.Name} to ${data.new[objectKey]}`);
            return messages;
          }

          messages.push(`updated ${objectKey} property to ${data.new[objectKey]}`);

          return messages;
        }, []);

        return messages.join(', ');
      }

      if (event === 'Deleted village post') {
        return `Deleted post: ${data.subject}`;
      }

      if (event === 'Updated village post') {
        return `Updated post: ${data.new.subject}`;
      }

      if (event === 'Suspicious activity') {
        return `Reason: ${data.triggerName}`;
      }

      return '';
    },
  }));

export default types
  .model('UserActivityLog', {
    entries: types.array(LogEntry),
  })
  .volatile((self) => ({
    isAllLoaded: false,
    lastLoadedEntryDoc: types.frozen(null),
  }))
  .actions((self) => ({
    load: flow(function* ({ orderBy = 'time', order = 'desc', limit = 50, startAfter } = {}) {
      try {
        let docRef = db
          .collection('users')
          .doc(getParent(self).id)
          .collection('activity_log')
          .orderBy(orderBy, order)
          .limit(limit);

        if (startAfter) {
          docRef = docRef.startAfter(startAfter);
        }

        const result = yield docRef.get();

        snapToArray(result).forEach((log) => {
          self.entries.push(LogEntry.create(log));
        });

        self.lastLoadedEntryDoc = result.docs.length > 0 ? result.docs[result.docs.length - 1] : null;
        self.isAllLoaded = result.size < limit;
      } catch (error) {
        console.error(error);
      }
    }),
  }));
