import { types, flow, getSnapshot, getParent, getRoot } from 'mobx-state-tree';
import ProgramArticle from './ProgramArticle';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';

const db = firebase.firestore();

export default types
  .model('ProgramArticleManager', {
    articles: types.array(ProgramArticle),
  })
  .views((self) => ({
    hasLoaded(programID) {
      return self.loaded && (self.loaded === 'all' || self.loaded === programID);
    },
    get drafts() {
      return getParent(self).drafts;
    },
    get empty() {
      return self.articles.length === 0;
    },
    articleBefore(article) {
      let articles = self.fromProgram(article.programId);
      let index = articles.indexOf(article);
      if (index === -1) {
        return null;
      }
      let prevIndex = Math.max(0, index - 1);
      return prevIndex === index ? null : articles[prevIndex];
    },
    articleAfter(article) {
      let articles = self.fromProgram(article.programId);
      let index = articles.indexOf(article);
      if (index === -1) {
        return null;
      }
      let nextIndex = Math.min(articles.length - 1, index + 1);
      return nextIndex === index ? null : articles[nextIndex];
    },
    fromProgram(programID, keywords, tags, topics) {
      keywords = typeof keywords === 'string' ? keywords.toLowerCase() : '';
      const articles = self.articles.filter((article) => {
        return (
          article.programId === programID &&
          (keywords ? article.title.toLowerCase().includes(keywords) : true) &&
          (tags && tags.length
            ? article.tags.reduce((m, val) => {
                return m || tags.includes(val);
              }, false)
            : true) &&
          (topics && topics.length ? topics.includes(article.topic) : true)
        );
      });

      articles.sort((a, b) => {
        return a.sortIndex - b.sortIndex;
      });

      return articles;
    },
    tagsFromProgram(programID) {
      const articles = self.fromProgram(programID);

      const tags = [];
      for (let article of articles) {
        for (let tag of article.tags) {
          if (!tags.includes(tag)) {
            tags.push(tag);
          }
        }
      }

      // merge tags from the program tabs
      let program = getRoot(self).programs.get(programID);
      let programTabTags = program.tabs.map((tab) => tab.tag);
      for (let tag of programTabTags) {
        if (!tags.includes(tag)) {
          tags.push(tag);
        }
      }

      tags.sort();
      return tags;
    },
    get topics() {
      return [
        'How to use this Program',
        'Night Sleep',
        'Napping',
        'Settling',
        'Sleep Environment',
        'Feeding & Nutrition',
        'Parenting',
        'Sickness',
        'Self Care',
        'Toilet Training',
        'Schedules',
        'Menus',
        'Recipes',
        'Family Recipes',
        'Behaviour & Development',
      ];
    },
    get(id) {
      return self.articles.find((article) => article.id === id);
    },
  }))
  .volatile((self) => ({
    loaded: '',
    loading: false,
  }))
  .actions((self) => ({
    pushArticle(article) {
      self.articles.push(article);
      return self.articles[self.articles.length - 1];
    },
    removeArticle(articleID) {
      const index = self.articles.findIndex((a) => a.id === articleID);
      if (index >= 0) {
        let arr = self.articles.slice(0);
        arr.splice(index, 1);
        self.articles = arr;
      }
    },
    load: flow(function* (programID) {
      self.loading = true;
      yield self.drafts.ensureListeningUnpublished();
      let query = db.collection('program_articles');
      if (programID) {
        query = query.where('programId', '==', programID);
      }
      const articleData = yield query.orderBy('sortIndex', 'asc').get();
      let articles = self.drafts.replaceData('program-article', snapToArray(articleData));
      articles.sort((a, b) => {
        return a.sortIndex - b.sortIndex;
      });
      self.articles = articles;
      self.loaded = programID || 'all';
      self.loading = false;
    }),
    loadOne: flow(function* (id) {
      self.loading = true;
      yield self.drafts.ensureListeningUnpublished();
      const articleData = yield db.collection('program_articles').doc(id).get();
      self.articles = self.drafts.replaceData('program-article', snapToArray([articleData]));
      self.loading = false;
    }),
    applySort: flow(function* (newArticlesOrdered) {
      newArticlesOrdered = newArticlesOrdered.map((art) => Object.assign({}, getSnapshot(art)));
      for (let sortIndex = 0; sortIndex < newArticlesOrdered.length; sortIndex++) {
        newArticlesOrdered[sortIndex].sortIndex = sortIndex;
      }
      self.articles = newArticlesOrdered;
      const updates = [];

      for (let article of self.articles) {
        // save changes
        updates.push(article.update('sortIndex', article.sortIndex));
      }

      yield Promise.allSettled(updates);
    }),
  }));
