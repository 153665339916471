import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

// layout HOC
import withLayout from '../components/Layout';
import { allFlattenRoutes as routes } from './index';

const Routes = (routesProps) => (
  <BrowserRouter>
    <Switch>
      {routes.map((route, index) => {
        return (
          !route.children && (
            <route.route
              key={index}
              path={route.path}
              roles={route.roles}
              exact={route.exact}
              component={withLayout((props) => {
                return <route.component rootStore={routesProps.rootStore} {...props} />;
              })}></route.route>
          )
        );
      })}
    </Switch>
  </BrowserRouter>
);

export default inject('rootStore')(observer(Routes));
