import { types, flow, getParent } from 'mobx-state-tree';
import Event from './Event';
import EventManagerGrid from './EventManagerGrid';
import ssevents from './../helpers/events';
import { snapToArray } from './../helpers/firestore';
import firebase from './../helpers/firebase';
import EventSchedulePreview from './EventSchedulePreview';
const db = firebase.firestore();

export default types
  .model('EventManager', {
    events: types.array(Event),
    grid: types.optional(EventManagerGrid, {}),
    changed: types.array(types.reference(Event)),
    eventSchedulePreview: types.optional(EventSchedulePreview, {}),
  })
  .volatile((self) => ({
    saving: false,
    loadIndicator: false,
    loadingMsg: '',
  }))
  .views((self) => ({
    get drafts() {
      return getParent(self).drafts;
    },
    get hasUnsavedChanges() {
      return self.changed.length > 0;
    },
    hasChanged(eventID) {
      return self.changed.findIndex((e) => e.id === eventID) >= 0;
    },
    get empty() {
      return self.events.length === 0;
    },
    get loading() {
      return self.empty || self.loadIndicator;
    },
    get(id) {
      return self.events.find((et) => et.id === id);
    },
    get all() {
      return self.events;
    },
    get sorted() {
      return self.events.slice(0).sort((a, b) => {
        if (a.startFrom < b.startFrom) {
          return -1;
        }
        if (a.startFrom > b.startFrom) {
          return 1;
        }
        return 0;
      });
    },
    currentGrid(weekStart, weekEnd) {
      const checkedEvents = [];
      const events = self.sorted.filter((event) => event.isEventInWeekRange(weekStart, weekEnd));

      for (const event of events) {
        const previousSameTimeEvent = checkedEvents.filter(
          (e) => e.startFrom === event.startFrom && e.isEventInWeekRange(event.ageWeeksMin, event.ageWeeksMax)
        );
        event.pushed = previousSameTimeEvent.length;
        checkedEvents.push(event);
      }

      return events;
    },
    getOverlappingEvents() {
      const cases = [];
      const events = self.sorted;
      const reported = [];
      for (let event of events) {
        for (let e of events) {
          if (e.id === event.id) continue;
          if (reported.includes(e.id) && reported.includes(event.id)) continue;
          if (e.name === event.name && e.startFrom === event.startFrom) {
            // check these
            if (e.isEventInWeekRange(event.ageWeeksMin, event.ageWeeksMax)) {
              reported.push(e.id);
              reported.push(event.id);
              cases.push([event, e]);
            }
          }
        }
      }
      return cases;
    },
    getCopyEvents() {
      return self.sorted.filter((event) => event.name.includes('Copy '));
    },
    getConsecutiveSleepEvent(weeks) {
      const events = self.sorted;
      const result = new Map();

      for (let week of weeks) {
        const weekEvents = ssevents.getWeekEvents(0, week.number, events, [], []);
        const firstSleepIndex = weekEvents.findIndex((event) => event.typeId === 'sleep');
        if (firstSleepIndex < 0) continue;

        let consecutiveSleepEvents = [];
        consecutiveSleepEvents.push(weekEvents[firstSleepIndex]);
        let lastSleepEventType = 'sleep';

        for (let i = firstSleepIndex + 1; i !== firstSleepIndex; i++) {
          if (i >= weekEvents.length) {
            i = 0;
          }

          const event = weekEvents[i];

          if (event.typeId === 'sleep') {
            consecutiveSleepEvents.push(event);

            if (lastSleepEventType === 'sleep') {
              result.set(week.id, consecutiveSleepEvents);
              continue;
            }

            lastSleepEventType = 'sleep';
          } else if (event.typeId === 'wake' || event.name === 'Wake') {
            consecutiveSleepEvents = [];
            lastSleepEventType = 'wake';
          }
        }
      }

      return result;
    },
    getConsecutiveWakeEvent(weeks) {
      const events = self.sorted;
      const result = [];

      for (let week of weeks) {
        const weekEvents = ssevents
          .getWeekEvents(0, week.number, events, [], [])
          .filter((event) => event.startFrom >= '08:00');

        if (weekEvents?.length) {
          const firstWakeIndex = weekEvents.findIndex((event) => event.typeId === 'wake' && !event.hidden);

          if (firstWakeIndex < 0) continue;

          let consecutiveWakeEvents = [];
          consecutiveWakeEvents.push(weekEvents[firstWakeIndex]);
          let lastWakeEventType = 'wake';

          let loop = 0;
          for (let i = firstWakeIndex + 1; i !== firstWakeIndex; i++) {
            if (i >= weekEvents.length) {
              i = 0;
              loop++;
              if (loop > 2) {
                break;
              }
            }

            const event = weekEvents[i];

            if (event.typeId === 'wake' && !event.hidden) {
              consecutiveWakeEvents.push(event);

              if (lastWakeEventType === 'wake') {
                result.push({ week: week.number, events: consecutiveWakeEvents });
                continue;
              }

              lastWakeEventType = 'wake';
            } else if (event.typeId === 'sleep' || event.name === 'Sleep') {
              consecutiveWakeEvents = [];
              lastWakeEventType = 'sleep';
            }
          }
        }
      }

      return result;
    },
  }))
  .actions((self) => ({
    markChanged(eventID) {
      self.changed.push(eventID);
    },
    markUnchanged(eventID) {
      const index = self.changed.findIndex((e) => e.id === eventID);
      if (index >= 0) {
        self.changed.splice(index, 1);
      }
    },
    load: flow(function* () {
      yield self.drafts.ensureListeningUnpublished();
      self.loadIndicator = true;
      const eventsData = yield db.collection('events').orderBy('startFrom', 'asc').get();
      const events = self.drafts.replaceData('event', snapToArray(eventsData));
      events.sort((a, b) => {
        return a.startFrom.localeCompare(b.startFrom);
      });
      self.events = events;
      yield new Promise((resolve) => setTimeout(resolve, 500));
      self.loadIndicator = false;
    }),
    setLoadingMsg(msg) {
      self.loadingMsg = msg;
    },
    addEvent: flow(function* (data) {
      const ref = db.collection('events').doc();
      const newID = ref.id;
      const newEventData = Object.assign(
        {},
        {
          groupName: '',
          typeId: '',
          name: '',
          conditionalText: '',
          ageWeeksMin: 0,
          ageWeeksMax: 0,
          description: '',
          startFrom: '02:00',
          startTo: '',
          duration: 0,
          stateDuration: 0,
          stateDurationText: '',
          optional: false,
          troubleshootingTips: [],
          notifications: [],
          timePeriodLabel: '',
        },
        data,
        {
          id: newID,
        }
      );
      self.events.push(newEventData);
      const event = self.events.find((e) => e.id === newID);
      event.setIsNew(true);
      event.getDraft().setOperation('create');
      yield event.save();
      return event;
    }),
    removeEvent: flow(function* (event) {
      self.markUnchanged(event.id);
      self.grid.closeEventView();
      yield event.delete();
      self.events.splice(
        self.events.findIndex((e) => e.id === event.id),
        1
      );
    }),
    save: flow(function* () {
      self.saving = true;
      self.setTimePeriodLabels();
      for (let changedEvent of self.changed) {
        yield changedEvent.save();
      }
      self.saving = false;
      self.loadingMsg = '';
      self.changed = [];
    }),
    setTimePeriodLabels() {
      /**
      20:00 - 04:59 - Night
      05:00 - 11:59 - Morning
      12:00 - 16:59 - Afternoon
      17:00 - 19:59 - Evening
    */
      const times = [
        {
          label: 'Night',
          from: '20:00',
          to: '04:59',
        },
        {
          label: 'Morning',
          from: '05:00',
          to: '11:59',
        },
        {
          label: 'Afternoon',
          from: '12:00',
          to: '16:59',
        },
        {
          label: 'Evening',
          from: '17:00',
          to: '19:59',
        },
      ];
      for (let event of self.events) {
        let time = event.startFrom;
        let label = times.find((t) => {
          if (t.from > t.to) {
            //  night
            return (t.from <= time && '23:59' >= time) || ('00:00' <= time && t.to >= time);
          } else {
            return t.from <= time && t.to >= time;
          }
        }).label;
        if (event.timePeriodLabel !== label) {
          event.set('timePeriodLabel', label);
        }
      }
    },
  }));
