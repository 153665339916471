function getMinutesFromTimeString(time) {
  const [hours, minutes] = time.split(':').map((p) => parseInt(p, 10));
  return hours * 60 + minutes;
}

/**
 * Get how many minutes are there between two times
 * @param {string} time1 "start" Time i.e. 18:00
 * @param {string} time2 "end" Time i.e. 03:00 (overnight)
 */
function getMinutesBetween(time1, time2) {
  let time1min = getMinutesFromTimeString(time1);
  let time2min = getMinutesFromTimeString(time2);
  let diff;
  if (time1 > time2) {
    // overnight
    diff = Math.abs(24 * 60 - time1min + time2min);
  } else {
    diff = Math.abs(time2min - time1min);
  }
  return diff;
}

/**
 * Get duration (in minutes) as text
 * @param {number} mins Minutes
 */
function renderDuration(state, mins) {
  const hours = Math.floor(mins / 60);
  mins = mins % 60;

  if (state === 'asleep' && hours > 6) {
    return 'Sweet dreams!';
  }

  let hText = '';
  if (hours < 1) {
    hText = '';
  } else if (hours === 1) {
    hText = '1 hr';
  } else {
    hText = `${hours} hrs`;
  }

  let mText = '';
  if (mins < 1) {
    mText = '';
  } else if (mins === 1) {
    mText = '1 min';
  } else {
    mText = `${mins} mins`;
  }

  let timeText = `${hText} ${mText}`.trim();

  if (state === 'awake') {
    return 'Awake time ' + timeText;
  } else {
    return 'Sleep time ' + timeText + ' max';
  }
}

function addStateData(events) {
  // let firstSleepIndex = events.findIndex((event) => event['typeId'] === 'sleep');
  // let firstSleep = events[firstSleepIndex];
  // let nextEvent = firstSleep;
  // let lastEventType = 'sleep';
  // let awakeEvents = [];
  // let asleepEvents = [];
  // let lastSleepEvent = null;
  // let i = firstSleepIndex;
  // let loop = -1;

  // if (!firstSleep) {
  //   return [];
  // }

  // // cycle through all events and make a full loop (24 hours), starting with a sleep event
  // do {
  //   i++;
  //   if (i >= events.length) {
  //     i = 0;
  //   }
  //   let event = nextEvent;
  //   nextEvent = events[i];

  //   // SLEEP EVENT
  //   if (event['typeId'] === 'sleep') {
  //     lastEventType = 'sleep';
  //     // is the next event a sleep event?
  //     // if (nextEvent.typeId === 'sleep') {
  //     // special case, take the next event and calculate from there
  //     // console.log('Next event is sleep event!');
  //     // event = nextEvent;
  //     // }

  //     // - all events from (including) last wake event, have their awake time updated
  //     if (awakeEvents.length > 0) {
  //       // update all awake events with time between lastWakeEvent and this sleep event startFrom
  //       let stateDuration, stateDurationText;
  //       for (let awakeEvent of awakeEvents) {
  //         if (awakeEvent.typeId === 'wake') {
  //           stateDuration = getMinutesBetween(awakeEvent['startFrom'], event['startFrom']);
  //           stateDurationText = renderDuration('awake', stateDuration);
  //         }
  //         // console.log('- awake ' + awakeEvent.id, stateDuration, stateDurationText);
  //         // console.log(awakeEvent['startFrom'], awakeEvent['name'], 'awake', stateDurationText);
  //         awakeEvent.stateDuration = stateDuration;
  //         awakeEvent.stateDurationText = stateDurationText;
  //       }
  //       awakeEvents = [];
  //     }

  //     lastSleepEvent = event;
  //     asleepEvents.push(event);

  //     // WAKE EVENT
  //   } else if (event['typeId'] === 'wake' || event['name'] === 'Wake') {
  //     lastEventType = 'wake';
  //     // - all events from (including) last sleep event, have their sleep time updated
  //     if (asleepEvents.length > 0) {
  //       // update all asleep events with time between lastSleepEvent and this wake event startFrom
  //       let stateDuration = '';
  //       if (event.name.toLowerCase() === 'wake' && event.startTo && event.startFrom < '11:00') {
  //         stateDuration = getMinutesBetween(lastSleepEvent['startFrom'], event['startTo']);
  //       } else {
  //         stateDuration = getMinutesBetween(lastSleepEvent['startFrom'], event['startFrom']);
  //       }
  //       let stateDurationText = renderDuration('asleep', stateDuration);
  //       for (let asleepEvent of asleepEvents) {
  //         // console.log('- awake ' + awakeEvent.id, stateDuration, stateDurationText);
  //         // console.log(asleepEvent['startFrom'], asleepEvent['name'], 'asleep', stateDurationText, lastSleepEvent['startFrom'], event['startFrom'], event['name']);
  //         if (asleepEvent.typeId !== 'wake') {
  //           asleepEvent.stateDuration = stateDuration;
  //           asleepEvent.stateDurationText = stateDurationText;
  //         }
  //       }
  //       asleepEvents = [];
  //     }

  //     awakeEvents.push(event);
  //   } else {
  //     switch (lastEventType) {
  //       case 'sleep':
  //         asleepEvents.push(event);
  //         break;
  //       case 'wake':
  //         awakeEvents.push(event);
  //         break;
  //     }
  //   }

  //   // detect two consecutive sleep events
  //   if (event['typeId'] === 'sleep' && awakeEvents.length === 0 && asleepEvents.length > 0) {
  //     console.log('here', { asleepEvents, event });
  //     for (let asleepEvent of asleepEvents) {
  //       asleepEvent.stateDuration = 0;
  //       asleepEvent.stateDurationText = `Sleep time N/A: No wake event`;
  //     }
  //   }

  //   if (event === firstSleep) {
  //     loop++;
  //   }
  // } while (loop < 1);

  // // invalid cases default to inherit from next event if same type or, 0/empty text
  // for (let i = 0; i < events.length; i++) {
  //   let event = events[i];
  //   if (!event.stateDuration) {
  //     if (events[i + 1] && events[i + 1].typeId === event.typeId && event.typeId === 'sleep') {
  //       event.stateDuration =
  //         ((events[i + 1] && events[i + 1].stateDuration) || 0) +
  //         getMinutesBetween(event.startFrom, events[i + 1].startFrom);
  //       event.stateDurationText = event.stateDurationText || (events[i + 1] && events[i + 1].stateDurationText) || '';
  //     } else if (events[i + 1] && events[i + 1].typeId === event.typeId) {
  //       event.stateDuration = (events[i + 1] && events[i + 1].stateDuration) || 0;
  //       event.stateDurationText = (events[i + 1] && events[i + 1].stateDurationText) || '';
  //     } else {
  //       event.stateDuration = 0;
  //       event.stateDurationText = '';
  //     }
  //   }
  // }

  const firstSleepIndex = events.findIndex((event) => event.typeId === 'sleep');
  const firstSleep = events[firstSleepIndex];

  if (!firstSleep) return events;

  let asleepEvents = [];
  let awakeEvents = [];
  asleepEvents.push(firstSleep);
  let lastSleepEvent = firstSleep;
  let lastEventType = 'sleep';

  let i = firstSleepIndex;
  do {
    i++;
    if (i >= events.length) {
      i = 0;
    }

    const event = events[i];

    if (event.typeId === 'sleep' && lastEventType === 'sleep' && awakeEvents.length === 0) {
      // asleepEvents.forEach((asleepEvent) => {
      // asleepEvent.stateDuration = 0;
      // asleepEvent.stateDurationText = `Sleep time N/A: No wake event`;
      // });
      // asleepEvents = [];
    } else if (event.typeId === 'sleep' && lastEventType === 'wake' && awakeEvents.length > 0) {
      for (const awakeEvent of awakeEvents) {
        const stateDuration = getMinutesBetween(awakeEvent['startFrom'], event['startFrom']);
        const stateDurationText = renderDuration('awake', stateDuration);
        awakeEvent.stateDuration = stateDuration;
        awakeEvent.stateDurationText = stateDurationText;
      }
      awakeEvents = [];
    } else if (
      (event.typeId === 'wake' || event.name === 'Wake') &&
      lastEventType === 'sleep' &&
      asleepEvents.length > 0
    ) {
      for (const asleepEvent of asleepEvents) {
        let stateDuration;
        if (event.name.toLowerCase() === 'wake' && event.startTo && event.startFrom < '11:00') {
          stateDuration = getMinutesBetween(lastSleepEvent['startFrom'], event['startTo']);
        } else {
          stateDuration = getMinutesBetween(lastSleepEvent['startFrom'], event['startFrom']);
        }

        const stateDurationText = renderDuration('asleep', stateDuration);
        asleepEvent.stateDuration = stateDuration;
        asleepEvent.stateDurationText = stateDurationText;
      }
      asleepEvents = [];
    }

    if (event.typeId === 'sleep') {
      asleepEvents.push(event);
      lastSleepEvent = event;
      lastEventType = 'sleep';
    } else if (event.typeId === 'wake' || event.name === 'Wake') {
      awakeEvents.push(event);
      lastEventType = 'wake';
    } else {
      if (!lastEventType || lastEventType === 'sleep') {
        asleepEvents.push(event);
      } else if (lastEventType === 'wake') {
        awakeEvents.push(event);
      }
    }
  } while (i !== firstSleepIndex);

  return events;
}

function prefixNum(num) {
  if (num < 10) {
    return '0' + num;
  } else {
    return num;
  }
}
function replacer(offset, h24mode) {
  return function replacerRegex(match, g1, g2) {
    let minBefore = g1.split(':').map((val) => parseInt(val, 10));
    minBefore = minBefore[0] * 60 + minBefore[1];
    let minAfterDiff = minBefore + offset;
    let minAfter = Math.abs(minAfterDiff);
    let timeStr = '';
    if (h24mode) {
      if (minAfter >= 24 * 60) {
        minAfter = minAfter - 24 * 60;
      }
      return `${prefixNum(Math.floor(minAfter / 60))}:${prefixNum(minAfter % 60)}`;
    }
    if (minAfter >= 12 * 60) {
      let diff = minAfter - 12 * 60;
      if (diff >= 60) {
        timeStr = `${Math.floor(diff / 60)}:${prefixNum(diff % 60)}`;
      } else {
        timeStr = `12:${prefixNum(diff)}`;
      }
    } else if (minAfter < 60) {
      timeStr = `12:${prefixNum(minAfter)}`;
    } else {
      timeStr = `${Math.floor(minAfter / 60)}:${prefixNum(minAfter % 60)}`;
    }
    let AMPM = '';
    if (g2) {
      AMPM += ' ';
      if (g2.toUpperCase() === 'AM') {
        if (minBefore < 12 * 60 && minAfter >= 12 * 60) {
          AMPM += 'PM';
        } else if (minBefore < 12 * 60 && minAfterDiff < 0) {
          AMPM += 'PM';
        } else {
          AMPM += g2;
        }
      } else if (g2.toUpperCase() === 'PM') {
        if ((minBefore < 12 * 60 && minAfter >= 12 * 60) || (minBefore >= 12 * 60 && minAfter < 12 * 60)) {
          AMPM += 'AM';
        } else if (minBefore < 12 * 60 && minAfterDiff < 0) {
          AMPM += 'AM';
        } else {
          AMPM += g2;
        }
      }
    } else if (match.includes(' ')) {
      AMPM = ' ';
    }
    return timeStr + AMPM;
  };
}

export default {
  getWeekEvents: function getWeekEvents(EventTimeOffset, week, allEvents, tips, allTips) {
    allEvents = allEvents.map((event) => {
      if (EventTimeOffset !== 0) {
        return Object.assign({}, event, {
          startFrom: event.startFrom.replace(/(\d?\d:\d\d) ?(AM|PM)?/g, replacer(EventTimeOffset, true)),
          startTo: event.startTo.replace(/(\d?\d:\d\d) ?(AM|PM)?/g, replacer(EventTimeOffset, true)),
          description: event.description.replace(/(\d?\d:\d\d) ?(AM|PM)?/g, replacer(EventTimeOffset)),
        });
      } else {
        return event;
      }
    });

    let events = allEvents
      .filter((event) => {
        return event.ageWeeksMin <= week && event.ageWeeksMax >= week;
      })
      .map((e) => Object.assign({}, e));
    events.sort((a, b) => {
      if (a.startFrom < b.startFrom) {
        return -1;
      } else if (a.startFrom > b.startFrom) {
        return 1;
      } else {
        // same start time, always put wake events first
        let aIsWake = a.name === 'Wake' || a.typeId === 'wake';
        let bIsWake = b.name === 'Wake' || b.typeId === 'wake';
        if (aIsWake && !bIsWake) {
          return -1;
        } else if (!aIsWake && bIsWake) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    events = addStateData(events).map((event) => {
      if (event.troubleshootingTips && event.troubleshootingTips.length > 0) {
        let tipID = event.troubleshootingTips[0].id;
        if (!tips.find((tip) => tip.id === tipID)) {
          let tip = allTips.find((tip) => tip.id === tipID);
          if (!tip) return event;
          tips.push({
            id: tip.id,
            content:
              EventTimeOffset !== 0
                ? tip.content.replace(/(\d?\d:\d\d) ?(AM|PM)?/g, replacer(EventTimeOffset))
                : tip.content,
          });
        }
      }
      return event;
    });
    return events;
  },
  getMinutesFromTimeString,
};
