import { types, flow } from 'mobx-state-tree';
import firebase from './../helpers/firebase';
import { snapToArray } from './../helpers/firestore';
import Metric from './Metric';
import MetricSeriesCollection from './MetricSeriesCollection';
const db = firebase.firestore();

export default types
  .model('VillageMetricsManager', {
    metrics: types.optional(types.frozen(), []),
    series: types.optional(MetricSeriesCollection, {}),
    statusCounters: types.array(Metric),
  })
  .views((self) => ({}))
  .volatile((self) => ({
    loading: false,
    loaded: '',
    statusCounterListener: null,
  }))
  .actions((self) => ({
    _addStatusCounter(data) {
      self.statusCounters.push(data);
    },
    load: flow(function* (mode) {
      mode = mode || 'default';
      if (self.loaded === mode || self.loading) return;
      self.loading = true;
      self.statusCounterListener = db
        .collection('metrics')
        .where('timePeriod', '==', 'now')
        .where('objectType', '==', 'village')
        .onSnapshot((querySnap) => {
          const docs = querySnap.docs;
          for (let doc of docs) {
            let existing = self.statusCounters.find((m) => m.id === doc.id);
            if (existing) {
              // update
              existing.update(doc.get('value'), doc.get('count'));
            } else {
              // push
              self._addStatusCounter(
                Object.assign({}, doc.data(), {
                  id: doc.id,
                })
              );
            }
          }
        });

      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 15);
      startDate = startDate.toISOString().split('T').shift();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);
      endDate = endDate.toISOString().split('T').shift();

      if (mode === 'full') {
        startDate = new Date(2020, 5, 11);
        startDate = startDate.toISOString().split('T').shift();
      }

      let series = yield db
        .collection('metrics')
        .where('objectType', '==', 'village')
        .where('timePeriod', '==', 'processed')
        .get();
      series = snapToArray(series);
      for (let doc of series) {
        doc.series = doc.series.filter((s) => s.x >= startDate && s.x <= endDate);
      }

      self.series.setData(series);

      self.loading = false;
      self.loaded = mode;
    }),
    unload() {
      if (self.statusCounterListener) {
        self.statusCounterListener();
        self.statusCounterListener = null;
      }
    },
  }));
