import { types, getSnapshot, getParent } from 'mobx-state-tree';
import Icon from './Icon';

export default types
  .model('Program', {
    id: types.identifier,
    category: types.enumeration(['sleep', 'nutrition', 'other']),
    icon: Icon,
    articleCount: 0,
    visibleTo: types.array(types.string),
    getStartedArticleIDs: types.array(types.string),
    tabs: types.frozen(),
    name: types.string,
    sortIndex: types.number,
    ageWeeksMin: types.number,
    ageWeeksMax: types.number,
    description: types.string,
    image: types.string,
    imageContent: types.optional(types.string, ''),
    purchaseURL: '',
  })
  .views((self) => ({
    isGettingStartedArticle(articleID) {
      return self.getStartedArticleIDs.includes(articleID);
    },
  }))
  .actions((self) => ({
    toggleVisibleTo(val) {
      let newVal = self.visibleTo.slice(0);
      if (newVal.includes(val)) {
        newVal.splice(newVal.indexOf(val), 1);
      } else {
        newVal.push(val);
      }
      self.visibleTo = newVal;
      self.getDraft().setItemData('visibleTo', newVal);
    },
    getDraft() {
      const drafts = getParent(self, 2).drafts;
      const existing = drafts.getUnpublishedByItemID(self.id);
      if (existing) {
        return existing;
      } else {
        // create the draft
        return drafts.createForItem('program', self.name, getSnapshot(self), 'edit');
      }
    },
    addGettingStarted(articleID) {
      const ids = self.getStartedArticleIDs.slice(0);
      if (!ids.includes(articleID)) {
        ids.push(articleID);
        self.update('getStartedArticleIDs', ids);
      }
    },
    removeGettingStarted(articleID) {
      const ids = self.getStartedArticleIDs.slice(0);
      const index = ids.indexOf(articleID);
      if (index >= 0) {
        ids.splice(index, 1);
        self.update('getStartedArticleIDs', ids);
      }
    },
    update(key, value) {
      self[key] = value;
      self.getDraft().setItemLabel(self.name);
      return self.getDraft().updateItemData(key, value);
    },
    set(key, value) {
      self[key] = value;
      self.getDraft().setItemLabel(self.name);
      self.getDraft().setItemData(key, value);
    },
    save() {
      return self.getDraft().save();
    },
  }));
